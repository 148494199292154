// Emoji Directive, Usage:
//
// v-emoji="`:tada: Congrats!`"                          Strings that contain emoji
// v-emoji="myVar"                                       Variables that return strings that may contain emoji
// v-emoji:html="`:tada: <strong>Congrats!</strong>`"    Strings that contain markup and emoji
// v-emoji:html="myVar"                                  Variables that return strings that may contain markup and/or emoji

const convertEmojis = (el, binding, vnode) => {
  el.innerHTML = Donut.Emoji.convertText(binding.value, binding.arg !== 'html');
};

export default {
  mounted(el, binding, vnode) {
    convertEmojis(el, binding, vnode);
  },

  updated(el, binding, vnode) {
    if (binding.oldValue !== binding.value) {
      convertEmojis(el, binding, vnode);
    }
  },
};
