export default {
  siteShow(tag) {
    return `/${tag.route_flow_type}/tags/${tag.id}/site`;
  },
  siteEdit(tag) {
    return `/${tag.route_flow_type}/tags/${tag.id}/site/edit`;
  },
  siteSave(tag) {
    return `/${tag.route_flow_type}/tags/${tag.id}/site`;
  },
};
