export default {
  callbackArgs(args) {
    return {
      success: (data) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('API#success');
          console.log(data);
          console.log(' ');
        }

        if (args.success) {
          args.success(data);
        }
      },
      error: (error) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('API#error');
          console.log(error);
          console.log(' ');
        }

        if (args.error) args.error(error);
      },
      complete: () => {
        if (args.complete) args.complete();
      },
    };
  },

  buildFormData(data, resourceName, opts = {}) {
    const formData = opts.formData || new FormData();
    Object.keys(data).forEach((property) => {
      if (Object.keys(data).indexOf(property) !== -1) {
        if (typeof data[property] === 'object' && !(data[property] instanceof File)) {
          this.buildFormData(data[property], `${resourceName}[${property}]`, { formData });
        } else {
          formData.append(`${resourceName}[${property}]`, data[property]);
        }
      }
    });
    return formData;
  },

  request(url, method, data, args = {}) {
    if (process.env.NODE_ENV === 'development') {
      console.log('API#request');
      console.log(method);
      console.log(url);
      console.log(' ');
    }
    let ajaxArgs = { type: method, url };
    if (args.contentType === 'multipart/form-data' && args.resourceName) {
      ajaxArgs.contentType = false;
      ajaxArgs.processData = false;
      ajaxArgs.cache = false;
      ajaxArgs.timeout = 600000;
      ajaxArgs.data = this.buildFormData(data, args.resourceName);
    } else {
      ajaxArgs.contentType = 'application/json';
      if (data) ajaxArgs.data = JSON.stringify(data);
    }
    ajaxArgs = Object.assign({}, ajaxArgs, this.callbackArgs(args));
    $.ajax(ajaxArgs);
  },

  post(url, data, args) {
    this.request(url, 'POST', data, args);
  },

  patch(url, data, args) {
    this.request(url, 'PATCH', data, args);
  },

  put(url, data, args) {
    this.request(url, 'PUT', data, args);
  },

  get(url, args) {
    this.request(url, 'GET', null, args);
  },

  delete(url, data, args) {
    this.request(url, 'DELETE', data, args);
  },
};
