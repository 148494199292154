import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      identity: null,
    };
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
  },

  actions: {
    track(_context, { eventName, properties }) {
      const fullEventName = `HRIS Connect: ${eventName}`;
      const fullProperties = properties || {};
      window.mixpanel.track(fullEventName, fullProperties);
      window.FS.event(fullEventName); // ignore fullProperties
      window.Intercom('trackEvent', fullEventName, fullProperties);
    },

    createLinkToken({ dispatch }) {
      return new Promise((resolve, _reject) => {
        Network.post('/hris/create_link_token_as_json', null, {
          success: (data) => {
            resolve(data);
          },
          error: (_error) => {
            dispatch('setErrorToast', null, { root: true });
            resolve(false);
          },
        });
      });
    },

    onMergeLinkClick({ commit, dispatch }) {
      dispatch('track', { eventName: 'Clicked Merge Link Button' });
      commit('closeModal', null, { root: true });
    },

    abandonMergeConnection({ dispatch }) {
      dispatch('track', { eventName: 'Abandoned Merge Connection' });
    },

    createIntegration({ commit, dispatch }, token) {
      const params = { public_token: token };
      return new Promise((resolve, _reject) => {
        Network.post('/hris/create_integration_as_json', params, {
          success: (data) => {
            dispatch('setSuccessToast', 'Success! HRIS Integration Connected.', { root: true });
            dispatch('track', { eventName: 'Created Integration', properties: data });
            dispatch('pollForIntegrationState');
            commit('update', { key: 'identity', value: data.identity });
            resolve(true);
          },
          error: (_error) => {
            dispatch('setErrorToast', null, { root: true });
            resolve(false);
          },
        });
      });
    },

    updateOwnership({ rootState, commit, dispatch }, args) {
      commit('update', { key: 'formSubmitted', value: true }, { root: true });
      return new Promise((resolve, _reject) => {
        const params = { hris_identity: { user_id: args.new_owner_id } };
        Network.put(`/hris/${args.record_id}/update_as_json`, params, {
          success: (data) => {
            commit('update', { key: 'identity', value: data.identity });
            const userIsNewOwner = rootState.currentUser.id === data.identity.creator.id;
            const toastText = userIsNewOwner ? "You're" : `${data.identity.creator.name} is`;
            dispatch('setSuccessToast', `Success! ${toastText} the new owner of the integration.`, { root: true });
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast', error.responseJSON?.toast, { root: true });
            resolve(false);
          },
          complete: () => {
            commit('closeModal', null, { root: true });
            commit('update', { key: 'formSubmitted', value: false }, { root: true });
          },
        });
      });
    },

    getIntegration({ state }) {
      if (!state.identity) { return; }
      return new Promise((resolve, _reject) => {
        Network.get(`/hris/${state.identity.id}/show_as_json`, {
          success: (data) => {
            resolve(data);
          },
          error: (error) => {
            resolve(error);
          },
        });
      });
    },

    pollForIntegrationState({ commit, dispatch }) {
      let polling = setInterval(() => {
        dispatch('getIntegration').then(({ identity }) => {
          commit('update', { key: 'identity', value: identity });
          if (identity?.state === 'active') {
            dispatch('setSuccessToast', 'Success! HRIS Integration is fully synced.', { root: true });
            dispatch('track', { eventName: 'Fully Synced Integration Successfully Polled' });
            clearInterval(polling);
            polling = null;
          }
        });
      }, 30000); // 30 seconds
    },
  },
};
