import API from 'lib/api/api';

export default {
  updateChannelProgramOwnership({ commit, dispatch }, args) {
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      const callbacks = {
        success: (data) => {
          commit('updateChannelProgramOwnership', data);
          const toastText = data.isNewOwner ? "You're" : `${data.newOwner.name} is`;
          dispatch('setSuccessToast', `Success! ${toastText} the new owner of ${data.channelName}.`);
          resolve(true);
        },
        error: (error) => {
          dispatch('setErrorToast');
          resolve(false);
        },
        complete: () => {
          commit('update', { key: 'formSubmitted', value: false });
          commit('closeModal');
        },
      };
      API[`${args.program_type}s`].updateOwnership(args, callbacks);
    });
  },

  applyTemplate({ commit, dispatch }, args) {
    // While API.ChannelPrograms.applyTemplate technically hits a `..._as_json` endpoint,
    // it is functionally redirecting in a similar way as a standard controller endpoint.
    // As such, we set `formSubmitted` to be true so that the modal's buttons behave
    // as though we were submitting a form.
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      API.ChannelPrograms.applyTemplate(
        {
          success: (data) => {
            commit('update', {
              key: 'formSubmitted',
              value: true,
            });
            window.location.href = data.redirect_url;
            resolve(true);
          },
          error: (error) => {
            commit('update', { key: 'formSubmitted', value: false });
            dispatch('setErrorToast', error.responseJSON?.error);
            resolve(false);
          },
        },
        args,
      );
    });
  },

  getEditableChannelPrograms({ commit, dispatch }) {
    commit('startLoading', 'getEditablePrograms');
    return new Promise((resolve, reject) => {
      API.ChannelPrograms.getEditable({
        success: (data) => {
          commit('updateEditablePrograms', data);
          resolve(true);
        },
        error: (error) => {
          commit('closeModal');
          dispatch('setErrorToast');
          resolve(false);
        },
        complete: () => { commit('endLoading', 'getEditablePrograms'); },
      });
    });
  },

  openChannelProgramsSaveSuccessModal({ commit, dispatch, state }, args = {}) {
    if (!args.hideConfetti) commit('startConfetti');
    dispatch('channelProgramsSendNow/updateSuccessModal', { programs: state.successPrograms });
  },

  openChannelProgramsPlanUpgradeModal({ commit, rootState }, args) {
    // 'args' should match the key/value pairs in 'updateModal' in modal/mutations.js
    // as well as showContactSupportModal and trialAllowed to determine the subcomponent.
    let subcomponent;
    if (!rootState.currentUser.can_change_plan) {
      subcomponent = 'channel-programs-modals-plan-upgrade-contact-support';
    } else if (args.trialAllowed) {
      subcomponent = 'channel-programs-modals-plan-upgrade-trial';
    } else {
      subcomponent = 'channel-programs-modals-plan-upgrade-payment-required';
    }
    commit('updateModal', Object.assign({}, { visible: true, size: 'lg', subcomponent }, args));
    window.mixpanel.track('Channel Programs Plan Upgrade Modal - Opened', {
      version: subcomponent.split('channel-programs-modals-plan-upgrade-')[1],
      data: args.data,
    });
  },

  openBuildingBlockPreviewModal({ commit, dispatch, state }, { buildingBlock, viewOnly, cancelText }) {
    commit('updateModal', {
      visible: true,
      subcomponent: 'channel-programs-modals-building-block-preview',
      data: { buildingBlock, viewOnly, cancelText },
      cancelMutation: 'updateModal',
      cancelArgs: state.modal,
      size: 'xxl',
    });
  },

  closePrivateChannelModal({ commit, dispatch, state }, selectedChannel) {
    // Fill in the existing channel selector.
    if (state.modal.data.buildingBlock) {
      // Go back to the the existing channel selector modal with this channel selected.
      state.modal.cancelArgs.data.selectedChannel = selectedChannel;
    } else {
      const kind = (state.coffeeConfiguration || state.watercooler || state.celebrationProgram)?.type || 'CoffeeConfiguration';
      const updateMutation = `update${kind}`;
      commit(updateMutation, {
        key: 'channel',
        value: {
          ...selectedChannel,
          private: true,
          type: 'Channel',
        },
      });
      commit(updateMutation, {
        key: 'channel_uid',
        value: selectedChannel.uid,
      });
    }
    dispatch('cancelModal');
  },

  openExistingChannelModal({ commit, dispatch, state }, { buildingBlock, isAltChannelSelector }) {
    commit('updateModal', {
      visible: true,
      subcomponent: 'channel-programs-modals-channel-selector-existing',
      data: {
        buildingBlock,
        isAltChannelSelector,
        existingChannelName: state.modal.data?.selectedChannel,
      },
      cancelMutation: 'updateModal',
      cancelArgs: state.modal,
    });
  },
};
