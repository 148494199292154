import Network from './network';

export default {
  update(team, data, args) {
    Network.put(
      `/teams/${team.id}`,
      { team: data },
      args,
    );
  },
};
