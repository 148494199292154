import API from 'lib/api/api';

export default {
  createWatercoolerPack({ commit, state, dispatch }, params) {
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      API.WatercoolerPacks.create(
        state.watercooler.id,
        params,
        {
          success: (data) => {
            dispatch('updatePacksLibrary', data);
            dispatch('setSuccessToast', 'Successfully created Watercooler Pack!');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
          complete: () => {
            commit('closeModal');
            commit('update', { key: 'formSubmitted', value: false });
          },
        },
      );
    });
  },

  updateWatercoolerPack({ commit, state, dispatch }, params) {
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      API.WatercoolerPacks.update(
        state.watercooler.id,
        params,
        {
          success: (data) => {
            dispatch('updatePacksLibrary', data);
            dispatch('updateTopicsQueue', data);
            dispatch('setSuccessToast', 'Successfully updated Watercooler Pack!');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
          complete: () => {
            commit('closeModal');
            commit('update', { key: 'formSubmitted', value: false });
          },
        },
      );
    });
  },
};
