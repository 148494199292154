import API from 'lib/api/api';

const getSuggestedPromptsCallbacks = (commit, dispatch, resolve) => (
  {
    success: (data) => {
      const prompts = data.prompts.map(prompt => ({ message: prompt, selected: true }));
      commit('updateModalData', { suggestedPrompts: prompts });
      resolve(true);
    },
    error: (error) => {
      dispatch('setErrorToast');
      resolve(false);
    },
    complete: () => {
      commit('updateModalData', { isLoadingPrompts: false });
    },
  }
);

export default {
  createWatercoolerPrompt({ state, dispatch }, promptParams) {
    return new Promise((resolve, reject) => {
      API.WatercoolerPrompts.create(
        state.watercooler.id,
        promptParams,
        {
          success: (data) => {
            dispatch('updatePacksLibrary', data);
            dispatch('updateTopicsQueue', data);
            dispatch('setSuccessToast', 'Your topic was created!');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },

  createWatercoolerPrompts({ state, dispatch }, promptParams) {
    return new Promise((resolve, reject) => {
      const { watercooler, modal } = state;
      API.WatercoolerPrompts.bulkCreate(
        watercooler.id,
        promptParams,
        {
          success: (data) => {
            dispatch('updatePacksLibrary', data);
            dispatch('updateTopicsQueue', data);
            dispatch('setSuccessToast', 'Your topics were created!');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
        modal?.data?.pack?.id,
      );
    });
  },

  updateWatercoolerPrompt({ state, dispatch }, promptParams) {
    return new Promise((resolve, reject) => {
      API.WatercoolerPrompts.update(
        state.watercooler.id,
        promptParams.id,
        promptParams,
        {
          success: (data) => {
            dispatch('updatePacksLibrary', data);
            dispatch('updateTopicsQueue', data);
            dispatch('setSuccessToast', 'Your topic was updated!');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },

  destroyWatercoolerPrompt({ state, dispatch }, { promptId }) {
    return new Promise((resolve, reject) => {
      API.WatercoolerPrompts.destroy(
        state.watercooler.id,
        promptId,
        {
          success: (data) => {
            dispatch('updateTopicsQueue', data);
            dispatch('setSuccessToast', 'Your topic has been deleted.');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },

  openPromptPreview({ commit }, prompt) {
    commit('updateModal', {
      visible: true,
      subcomponent: 'watercoolers-modals-prompt-preview',
      data: { prompt },
    });
  },

  openChannelProgramsPlanUpgradeModalForWatercoolerPrompts({ dispatch, state }) {
    dispatch('openChannelProgramsPlanUpgradeModal', {
      trialAllowed: state.watercooler.service_account.paidCoffeeTrialAllowed,
      data: {
        featureTitleText: 'customize Watercooler prompts',
        source: 'watercooler-topics',
        featureInfo: {
          programKey: 'watercooler',
          feature: 'watercooler-custom',
        },
      },
    });
  },

  getSuggestedPrompts({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      const { watercooler, modal } = state;
      if (modal?.data?.pack) {
        API.WatercoolerPacks.suggestPrompts(
          modal.data.pack.id,
          getSuggestedPromptsCallbacks(commit, dispatch, resolve),
        );
      } else {
        API.WatercoolerPrompts.suggestPrompts(
          watercooler.id,
          getSuggestedPromptsCallbacks(commit, dispatch, resolve),
        );
      }
    });
  },
};
