export default {
  namespaced: true,

  state() {
    return {
      batches: null,
      flowType: null,
    };
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
  },
};
