import API from 'lib/api/api';

export default {
  showPacksLibrary({ dispatch, state }) {
    return new Promise((resolve, reject) => {
      API.Watercoolers.showPacksLibrary(
        state.watercooler.id,
        {
          success: (data) => {
            dispatch('updatePacksLibrary', data);
            resolve(true);
          },
          error: (error) => {
            resolve(false);
          },
        },
      );
    });
  },

  updateWatercooler({ state, commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      API.Watercoolers.update(
        state.watercooler.id,
        params,
        {
          success: (data) => {
            dispatch('updateTopicsQueue', data);
            commit('hideInactivePrompts', data.hideInactivePrompts);
            dispatch('setSuccessToast');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },

  reorderPromptsFromTable({ dispatch }, ids) {
    dispatch('updateWatercooler', { ordered_unsent_prompt_ids: ids });
  },

  // Topics Tab Refresh Actions

  updatePacksLibrary({ commit, state }, { packsLibrary, packIdToSelect }) {
    // First, update the entirety of the packs in the library with any new data
    commit('update', { key: 'packsLibrary', value: packsLibrary });
    // Then, select a new pack (if applicable)
    if (packIdToSelect) commit('drawer/updateDrawerData', { key: 'selectedItemId', value: packIdToSelect });
  },

  updateTopicsQueue({ commit, state }, { topicsQueue }) {
    commit('update', { key: 'packs', value: topicsQueue.packs });
    commit('update', { key: 'queuePrompts', value: topicsQueue.prompts });
    commit('updatePromptOrder', topicsQueue.promptIds);
  },
};
