export default {
  updateUserSelection(state, val) {
    const user = state.userResults.users.filter(u => u.id === val.member.id)[0];
    user.selected = val.isSelected;
  },
  toggleAllUserSelections(state, val) {
    for (const user of state.userResults.users) {
      user.selected = val;
    }
  },
  updateUserResultsUsers(state, val) {
    state.userResults.users = val;
  },
  updateUserResultsGhostCount(state, val) {
    state.userResults.ghostCount = val;
  },
  recordClosedAnnouncement(state, payload) {
    if (state.currentUser != null) {
      state.currentUser = payload;
    }
  },
  updateUser(_state, { user, key, value }) {
    user[key] = value;
  },
};
