import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      list: [],
    };
  },

  mutations: {
    updateIcebreaker(state, icebreaker) {
      const idx = state.list.findIndex(i => i.id === icebreaker.id);
      if (idx >= 0) {
        state.list[idx] = icebreaker;
      }
    },
  },

  actions: {
    getIcebreakers({ state, rootState, dispatch }, params) {
      return new Promise((resolve, reject) => {
        const callbacks = {
          success: (data) => {
            state.list = data;
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast', null, { root: true });
            resolve(false);
          },
        };

        if (rootState.coffeeConfiguration.id) {
          Network.get(`/coffee/${rootState.coffeeConfiguration.id}/icebreakers`, callbacks);
        } else {
          Network.get('/icebreakers/index_no_config', callbacks);
        }
      });
    },

    createIcebreaker({ commit, rootState, dispatch }, params) {
      commit('update', { key: 'formSubmitted', value: true }, { root: true });
      return new Promise((resolve, reject) => {
        Network.post(
          `/coffee/${rootState.coffeeConfiguration.id}/icebreakers`,
          params,
          {
            success: (data) => {
              commit('updateModal', {
                visible: true,
                subcomponent: 'coffee-form-modals-icebreakers',
                size: 'full-page',
                data: { fromIcebreakerCreate: true },
              }, { root: true });
              resolve(true);
            },
            error: (error) => {
              dispatch('setErrorToast', null, { root: true });
              resolve(false);
            },
            complete: () => {
              commit('update', { key: 'formSubmitted', value: false }, { root: true });
            },
          },
        );
      });
    },

    toggleIcebreaker({ rootState, commit, dispatch }, { icebreaker }) {
      return new Promise((resolve, _reject) => {
        Network.put(
          `/coffee/${rootState.coffeeConfiguration.id}/icebreakers/${icebreaker.id}`,
          { icebreaker: { active: !icebreaker.active } },
          {
            success: (data) => {
              commit('updateIcebreaker', data);
              dispatch('setSuccessToast', 'Your preferences have been saved!', { root: true });
              resolve(true);
            },
            error: (_error) => {
              dispatch('setErrorToast', null, { root: true });
              resolve(false);
            },
          },
        );
      });
    },
  },
};
