import API from 'lib/api/api';

export default {
  createWatercoolerPacksWatercooler({ commit, dispatch, state }, params) {
    const loaderName = `updateWatercoolerPackSubcription${params.pack_id}`;
    commit('startLoading', loaderName);
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      API.WatercoolerPacksWatercoolers.create(
        state.watercooler.id,
        params,
        {
          success: (data) => {
            dispatch('updateTopicsQueue', data);
            dispatch('updatePacksLibrary', data);
            dispatch('setSuccessToast', 'Your pack subscriptions have been updated.');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
          complete: () => {
            commit('closeModal');
            commit('endLoading', loaderName);
            commit('update', { key: 'formSubmitted', value: false });
          },
        },
      );
    });
  },

  destroyWatercoolerPacksWatercooler({ commit, dispatch, state }, params) {
    const loaderName = `updateWatercoolerPackSubcription${params.pack_id}`;
    commit('startLoading', loaderName);
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      API.WatercoolerPacksWatercoolers.destroy(
        state.watercooler.id,
        params,
        {
          success: (data) => {
            dispatch('updateTopicsQueue', data);
            dispatch('updatePacksLibrary', data);
            dispatch('setSuccessToast', 'Your pack subscriptions have been updated.');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
          complete: () => {
            commit('closeModal');
            commit('endLoading', loaderName);
            commit('update', { key: 'formSubmitted', value: false });
          },
        },
      );
    });
  },
};
