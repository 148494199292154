export default {
  updatePromptOrder(state, value) {
    state.queuePromptIds = value;
  },

  addPrompt(state, value) {
    state.queuePrompts[value.id] = value;
  },

  deactivatePrompt(state, value) {
    state.queuePrompts[value].active = false;
  },

  hideInactivePrompts(state, value) {
    state.watercooler.hide_inactive_prompts = value;
  },
};
