import URLHelper from './url-helper';

export default {
  onboarding(args = {}) {
    return URLHelper.build('/onboarding', args);
  },

  onboardingRequestAccess(args = {}) {
    return URLHelper.build('/onboarding/request_access', args);
  },

  onboardingActionItems(args = {}) {
    return URLHelper.build('/onboarding/action_items', args);
  },
};
