import API from 'lib/api/api';
import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      bundleQuickSetupType: 'hris_one_click_onboarding',
    };
  },

  getters: {
    journeyBundle(_state, _getters, _rootState, rootGetters) {
      return rootGetters['presetBundles/selectedBundle'];
    },

    journeyPreset(_state, getters, _rootState, _rootGetters) {
      return getters.journeyBundle.presets?.[0];
    },

    journeyName(_state, getters) {
      return getters.journeyBundle?.name;
    },

    channelIsRequired(_state, _getters, _rootState, rootGetters) {
      const reqs = rootGetters['presetBundles/selectedPresetsRequirements'];
      return reqs.some(req => req.type === 'channel');
    },
  },

  actions: {
    onPageView({ dispatch }) {
      dispatch('track', { eventName: 'viewed banner' });
    },

    onCreateClick({ dispatch }) {
      dispatch('track', { eventName: 'clicked create' });
      dispatch('getPresetBundle').then((_data) => {
        dispatch('openCreateJourneyModal');
      });
    },

    onPreviewClick({ dispatch }) {
      dispatch('track', { eventName: 'clicked preview' });
      dispatch('getPresetBundle').then((_data) => {
        dispatch('openPreviewModal');
      });
    },

    getPresetBundle({ state, getters, dispatch, commit }) {
      return new Promise((resolve, _reject) => {
        if (getters.journeyBundle) {
          resolve(getters.journeyBundle);
        } else {
          API.PresetBundles.getWithQuickSetup(state.bundleQuickSetupType, {
            success: (data) => {
              dispatch('presetBundles/initializeBundles', { bundles: data.bundles }, { root: true });
              const bundle = data.bundles?.[0];
              if (bundle) {
                commit('presetBundles/update', { key: 'flowTypeMetadata', value: data.flowTypeMetadata }, { root: true });
                commit('presetBundles/update', { key: 'selectedBundleId', value: bundle.id }, { root: true });
                resolve(data);
              } else {
                dispatch('setErrorToast', null, { root: true });
                resolve(false);
              }
            },
            error: (_error) => {
              dispatch('setErrorToast', null, { root: true });
              resolve(false);
            },
          });
        }
      });
    },

    openPreviewModal({ commit, getters }) {
      commit('updateModal', {
        visible: true,
        subcomponent: 'preset-bundles-modals-preset-preview',
        size: 'xl',
        data: { preset: getters.journeyPreset },
      }, { root: true });
    },

    openCreateJourneyModal({ commit, dispatch, getters }) {
      const bundle = getters.journeyBundle;
      if (getters.channelIsRequired) {
        commit('updateModal', {
          visible: true,
          subcomponent: 'preset-bundles-modals-new-tag',
          size: 'of-v',
          onSubmit: 'oneClickOnboarding/createTag',
          data: { bundle, hideName: true, hideAutoEnroll: true },
        }, { root: true });
      } else {
        dispatch('createTag');
      }
    },

    openAfterCreateJourneyModal({ commit }, journey) {
      commit('update', { key: 'formSubmitted', value: false }, { root: true });
      commit('updateModal', {
        visible: true,
        subcomponent: 'one-click-onboarding-modals-create-journey-success',
        onClose: 'oneClickOnboarding/onCreateJourneyModalClose',
        data: { journey },
      }, { root: true });
    },

    createTag({ dispatch, getters }, { event, channelId } = {}) {
      event?.stopPropagation();
      event?.preventDefault();
      const params = {
        channel_id: channelId,
        onboarding_tag: {
          name: getters.journeyBundle.name,
          flow_type: getters.journeyBundle.flow_type,
          preset_bundle_id: getters.journeyBundle.id,
          preset_ids: [getters.journeyPreset.id],
          auto_enroll: true,
        },
        source: 'one_click_onboarding',
        contentType: 'multipart/form-data',
        resourceName: 'onboarding_tag',
      };
      Network.post('/onboarding/tags', params, {
        success: (newJourney) => {
          dispatch('openAfterCreateJourneyModal', newJourney);
        },
        error: (_error) => {
          dispatch('setErrorToast', null, { root: true });
        },
      });
    },

    onCreateJourneyModalClose() {
      window.location.reload();
    },

    track(_context, { eventName, properties }) {
      const fullEventName = `One Click Onboarding: ${eventName}`;
      const fullProperties = properties || {};
      window.mixpanel.track(fullEventName, fullProperties);
      window.FS.event(fullEventName); // ignore fullProperties
      window.Intercom('trackEvent', fullEventName, fullProperties);
    },
  },
};
