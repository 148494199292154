import Network from './network';

export default {
  getWithQuickSetup(type, callbacks) {
    Network.get(
      `/prebuilt_journeys?quick_setup_type=${type}`,
      callbacks,
    );
  },
};
