<script>
  import { mapState, mapMutations } from 'vuex';
  import _ from 'lodash';
  import DonutSelexSearch from './search.vue';

  export default {
    extends: DonutSelexSearch,

    props: {
      remoteUrl: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        default: null,
      },
      searchKeys: {
        type: Array,
        default: () => [],
      },
      params: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {
        debouncedWatch: null,
      };
    },

    computed: {
      bestCacheKey() {
        let key = this.filterText;
        const { resultsCache } = this;

        if (key.length > 0) {
          while (key.length > 1) {
            if (typeof resultsCache[key] !== 'object') {
              key = key.slice(0, -1);
            } else {
              break;
            }
          }
          return key;
        }
        return null;
      },

      displayOptions() {
        const { bestCacheKey, resultsCache, searchedOptions } = this;

        if (resultsCache != null && bestCacheKey != null) {
          const searchResults = resultsCache[bestCacheKey];
          if (searchResults !== 'loading' && searchResults !== 'searching' && searchResults !== 'error') {
            const ids = searchResults || [];
            return ids.map(id => searchedOptions[id]);
          }
        }

        return [];
      },

      isLoading() {
        const { filterText, resultsCache } = this;

        if (filterText.length > 0) {
          const searchResults = resultsCache[filterText];
          return searchResults === 'loading' || searchResults === 'searching';
        }
        return false;
      },

      isError() {
        const { filterText, resultsCache } = this;

        if (filterText.length > 0) {
          const searchResults = resultsCache[filterText];
          return searchResults === 'error';
        }
        return false;
      },

      searchedOptions() {
        if (this.selexCache) {
          return this.selexCache.options[this.remoteUrl];
        }
      },

      resultsCache() {
        if (this.selexCache) {
          return this.selexCache.results[this.remoteUrl];
        }
      },

      extraParams() {
        // Override this in components that extend this component
        // to add params that are pecific to that component.
      },

      ...mapState([
        'selexCache',
      ]),
    },

    watch: {
      filterText(newValue, oldValue) {
        if (newValue.length > 0) {
          const searchResults = this.resultsCache[newValue];
          if (!searchResults || searchResults === 'searching' || searchResults === 'error') {
            this.updateSelexCache({ type: 'results', key: newValue, value: 'searching' });
            this.debouncedWatch(newValue, oldValue);
          }
        }
      },
    },

    created() {
      this.initializeVuexCache();
      // Debounce must be declared outside of methods, otherwise multiple uses
      // of the same component on the same page would use the same debouncer function
      this.debouncedWatch = _.debounce((newValue, oldValue) => {
        this.searchForOptions(newValue);
      }, 350);
    },

    beforeUnmount() {
      this.debouncedWatch.cancel();
    },

    methods: {
      initializeVuexCache() {
        if (!this.resultsCache || !this.searchedOptions) {
          if (!this.selexCache) {
            const defaultCache = {};
            defaultCache[this.remoteUrl] = {};
            this.update({
              key: 'selexCache',
              value: {
                results: defaultCache,
                options: defaultCache,
              },
            });
          } else {
            this.updateSelexCache({ type: 'results' });
            this.updateSelexCache({ type: 'options' });
          }
        }
      },

      initializeValue(value) {
        if (this.multiple) {
          const newSelectedKeys = [];
          value.forEach((option) => {
            const optionKey = option[this.valueKey];
            if (optionKey != null) {
              this.searchedOptions[optionKey] = option;
              newSelectedKeys.push(optionKey);
            }
            this.selectedKeys = newSelectedKeys;
          });
        } else {
          const optionKey = value?.[this.valueKey];
          if (optionKey && this.searchedOptions) {
            this.searchedOptions[optionKey] = value;
            this.selectedKey = optionKey;
          } else {
            this.selectedKey = null;
          }
        }
      },

      optionForKey(key) {
        if (this.searchedOptions != null) {
          return this.searchedOptions[key] || null;
        }
        return null;
      },

      // It's important to pass the value from the watcher rather than use this.filterText in case this.filterText
      // changes before all this is finished. That could lead to inaccurate cached results, or, at worst,
      // if the user deleted their search before this ajax call finishes, it would wipe the whole selexCache,
      // which would empty all of the remote selexes on the page.
      searchForOptions(filterText) {
        this.updateSelexCache({ type: 'results', key: filterText, value: 'loading' });
        const params = {
          query: encodeURIComponent(filterText),
          ...this.params,
          ...this.extraParams,
        };
        const queryString = Object.entries(params).map(kv => kv.join('=')).join('&');

        const joiner = this.remoteUrl.includes('?') ? '&' : '?';
        const url = [this.remoteUrl, queryString].join(joiner);
        $.ajax({
          url,
          success: (data) => { this.onRemoteSuccess(filterText, data); },
          error: (error) => { this.onRemoteError(filterText, error); },
        });
      },

      onRemoteSuccess(filterText, data) {
        const ids = data.map(option => option[this.valueKey]);
        data.forEach((option) => {
          this.updateSelexCache({ type: 'options', key: option[this.valueKey], value: option });
        });
        this.updateSelexCache({ type: 'results', key: filterText, value: ids });
      },

      onRemoteError(filterText, error) {
        this.updateSelexCache({ type: 'results', key: filterText, value: 'error' });
      },

      updateSelexCache({ type, key, value }) {
        this.$store.commit('updateSelexCache', {
          type,
          url: this.remoteUrl,
          key,
          value,
        });
      },

      ...mapMutations([
        'update',
      ]),
    },

  };
</script>
