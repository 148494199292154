export default {
  updateServiceAccountAdmins(state, { admins }) {
    state.userServiceAccount.admins = admins;
  },

  updateOwnNewConfigs(state, value) {
    state.userServiceAccount.isDefault = value;
  },

  updateAccountOwner(state, data) {
    state.userServiceAccount.owner = data.owner;
    state.userServiceAccount.admins = data.admins;
    state.canUpdateOwner = data.canUpdateOwner;
    state.serviceAccountIsManaged = true;
  },
};
