import URLHelper from './url-helper';

export default {
  presets(args = {}) {
    return URLHelper.build('/onboarding/presets', args);
  },

  preset(preset, args = {}) {
    let presetID;
    if (preset != null) presetID = preset.id;

    return URLHelper.build(`/onboarding/presets/${presetID}`, args);
  },

  newPreset(args = {}) {
    return URLHelper.build('/onboarding/presets/new', args);
  },
};
