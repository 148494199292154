import Network from './network';

export default {
  recordProgress(flag, args) {
    Network.post(
      '/user_details/record_progress',
      { user_detail: { flag } },
      args,
    );
  },
};
