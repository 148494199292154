import { mapState } from 'vuex';

export default {
  install: (app, options) => {
    app.mixin({
      computed: {
        ...mapState([
          'currentUser',
          'currentTeam',
        ]),
      },
    });
  },
};
