// This composable is used to get and set information stored in state that exists only client-side, such as query params and localStorage.
import { computed } from 'vue';

const useClientState = () => {
  // Query Parameters
  const queryParams = computed(() => {
    // This overlaps heavily with query_params.coffee, we want to move towards getting rid of all coffeescript soon
    const params = {};
    const queryString = window.location.search || '?';
    for (const param of queryString.substring(1).split('&')) {
      const parts = param.split('=');
      if (parts.length === 2) {
        let key = decodeURIComponent(parts[0]);
        let value = decodeURIComponent(parts[1]);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(value)) value = parseInt(value);
        // eslint-disable-next-line no-useless-escape
        if (key.match(/^[^\[]*\[\]/)) { // This means it's an array
          key = key.substring(0, key.length - 2);
          const arr = queryParams[key] || [];
          arr.push(value);
          params[key] = arr;
        } else {
          params[key] = value;
        }
      }
    }
    return params;
  });
  const getQueryParam = (key) => queryParams.value[key];

  // Local Storage
  const getLocalStorage = (key) => {
    let value = localStorage.getItem(key);
    // eslint-disable-next-line no-restricted-globals
    if (value && !isNaN(value)) value = parseInt(value);
    return value;
  };
  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  return { getQueryParam, getLocalStorage, setLocalStorage };
};

export default useClientState;
