import URLHelper from './url-helper';

export default {
  tags(args = {}) {
    if (['events', 'mentorship', 'training', 'offboarding', 'other'].includes(args.flowType)) {
      return URLHelper.build('/participants/tags', args);
    }
    return URLHelper.build('/onboarding/tags', args);
  },

  tag(tag, args = {}) {
    return URLHelper.build(`/${tag.route_flow_type}/tags/${tag.id}`, args);
  },
};
