import Network from './network';

export default {
  create(watercoolerId, params, callbacks) {
    Network.post(
      `/watercooler/${watercoolerId}/prompt_adjustments/create_as_json`,
      { watercooler_prompt_adjustment: params },
      callbacks,
    );
  },

  destroy(watercoolerId, params, callbacks) {
    Network.delete(
      `/watercooler/${watercoolerId}/prompt_adjustments/destroy_as_json`,
      { watercooler_prompt_adjustment: params },
      callbacks,
    );
  },
};
