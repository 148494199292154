import URLHelper from './url-helper';

export default {
  newCoffeeConfiguration(args = {}) {
    return URLHelper.build('/channel_programs/new?preview=intros', args);
  },

  coffeeConfiguration(coffeeConfiguration, args = {}) {
    if (!args.tab) args.tab = 'members';
    return URLHelper.build(`/coffee/${coffeeConfiguration.id}`, args);
  },

  editCoffeeConfiguration(coffeeConfiguration, args = {}) {
    return URLHelper.build(`/coffee/${coffeeConfiguration.id}/edit`, args);
  },
};
