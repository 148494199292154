import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      template: null,
      siteDataEndpoint: null,
      isPreview: true,
    };
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
  },

  actions: {
    track({ state }, { eventName, properties }) {
      const fullEvent = `Sites: ${eventName}`;
      const fullProperties = {
        ...properties,
        isPreview: state.isPreview,
      };
      window.mixpanel.track(fullEvent, fullProperties);
    },

    getModuleData({ state }, { module }) {
      const params = JSON.stringify(module);
      const url = `${state.siteDataEndpoint}?module=${encodeURIComponent(params)}`;
      return new Promise((resolve, reject) => {
        Network.get(
          url,
          {
            success: (data) => {
              resolve(data);
            },
            error: (error) => {
              reject(error);
            },
          },
        );
      });
    },

    answerPoll({ state }, { poll, answerIndex, onSuccess, onComplete }) {
      return new Promise((resolve, reject) => {
        Network.post(
          `/onboarding/poll_responses/${poll.response_id}/answer`,
          { answer_index: answerIndex, source: 'site' },
          {
            success: (data) => {
              onSuccess(data);
              resolve(data);
            },
            error: (error) => reject(error),
            complete: onComplete,
          },
        );
      });
    },

    answerTask({ state }, { taskAssignmentId, newState, onSuccess, onComplete }) {
      return new Promise((resolve, reject) => {
        Network.put(
          `/onboarding/task_assignments/${taskAssignmentId}`,
          { task_assignment: { state: newState }, source: 'site' },
          {
            success: (data) => {
              onSuccess(data);
              resolve(data);
            },
            error: (error) => reject(error),
            complete: onComplete,
          },
        );
      });
    },
  },
};
