import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      autoEnrollChannels: [],
      tag: null,
      tags: [],
      autoEnrollTags: [],
      flowType: null,
    };
  },

  getters: {
    autoEnrollChannelNames(state) {
      let names = '';
      if (state.autoEnrollChannels.length > 0) {
        names = state.autoEnrollChannels.map(channel => channel.name);
      } else if (state.autoEnrollTags.length > 0) {
        names = _.flatten(state.autoEnrollTags.map(tag => tag.auto_enroll_channels.map(channel => channel.name)));
      }

      return new Intl.ListFormat().format(names);
    },
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
  },

  actions: {
    track({ state }, { eventName, properties }) {
      const fullEventName = `Enroll by Channel Join: ${eventName}`;
      const fullProperties = properties || {};
      fullProperties.tagId = state.tag?.id;
      window.mixpanel.track(fullEventName, fullProperties);
      window.FS.event(fullEventName); // ignore fullProperties
      window.Intercom('trackEvent', fullEventName, fullProperties);
    },

    initialSetup({ commit }, { tag, flowType }) {
      commit('update', { key: 'tag', value: tag });
      commit('update', { key: 'flowType', value: flowType });
      commit('update', { key: 'autoEnrollChannels', value: tag?.auto_enroll_channels });
    },

    openSettingsModal({ state, commit, dispatch }, { tag, flowType }) {
      dispatch('track', { eventName: 'Opened Settings Modal' });
      if (tag && flowType) {
        dispatch('initialSetup', { tag, flowType });
      } else if (flowType) {
        commit('update', { key: 'flowType', value: flowType });
      }
      if (state.tag) {
        commit('updateModal', {
          visible: true,
          subcomponent: 'onboarding-tags-enrollment-channel-join-modals-settings',
          size: 'md of-y-v',
        }, { root: true });
      } else {
        dispatch('fetchTagsAndOpenModal');
      }
    },

    openSuccessModal({ commit }, selectedChannels) {
      commit('updateModal', {
        visible: true,
        subcomponent: 'onboarding-tags-enrollment-channel-join-modals-success',
        size: 'md',
        data: { selectedChannels },
      }, { root: true });
    },

    openWarningModal({ commit }) {
      commit('updateModal', {
        visible: true,
        subcomponent: 'onboarding-tags-enrollment-channel-join-modals-warning',
        size: 'md',
      }, { root: true });
    },

    submitSettingsModal({ dispatch, state }, { selectedChannels, selectedTag }) {
      if (selectedTag?.id) {
        state.tag = selectedTag;
      }
      if (selectedChannels.length === 0 && state.autoEnrollChannels.length > 0) {
        // only show the warning if they're clearing all previous selections
        dispatch('openWarningModal');
      } else {
        dispatch('updateChannels', { selectedChannels });
      }
    },

    submitWarningModal({ dispatch }) {
      dispatch('updateChannels', { selectedChannels: [] });
    },

    updateChannels({ commit, dispatch, state }, { selectedChannels }) {
      return new Promise((_resolve, _reject) => {
        const url = `/${state.flowType.routeResource}/tags/${state.tag.id}/auto_enroll_channels/bulk_update`;
        Network.put(
          url,
          { channels: selectedChannels },
          {
            success: (data) => {
              commit('update', { key: 'autoEnrollChannels', value: [] }); // To clear modal
              if (data.channels.length > 0) {
                const channelIds = data.channels.map(channel => channel.id);
                dispatch('track', { eventName: 'Selected Channels', channelIds });
                dispatch('openSuccessModal', selectedChannels);
              } else {
                dispatch('track', { eventName: 'Cleared Channels' });
                commit('closeModal', null, { root: true });
              }
            },
            error: () => {
              dispatch('setErrorToast', null, { root: true });
            },
          },
        );
      });
    },

    fetchAutoEnrollChannels({ state }, tagId) {
      const url = `/${state.flowType.routeResource}/tags/${tagId}/auto_enroll_channels/load_channels_as_json`;
      return new Promise((resolve) => {
        Network.get(url, {
          success: (response) => {
            const tag = state.tags.find(t => t.id === tagId);
            tag.auto_enroll_channels = response.channels;
          },
          complete: () => {
            resolve();
          },
        });
      });
    },

    fetchTagsAndOpenModal({ state, commit }) {
      Network.get(`/${state.flowType.routeResource}/tags`, {
        success: (response) => {
          commit('update', { key: 'tags', value: response });
          commit('updateModal', {
            visible: true,
            subcomponent: 'onboarding-tags-enrollment-channel-join-modals-settings',
            size: 'md of-y-v',
          }, { root: true });
        },
      });
    },
  },
};
