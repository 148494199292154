import Network from './network';

export default {
  getUser(args) {
    const queryParams = new URLSearchParams();
    if (args.includeCalIdentities) queryParams.append('include_cal_identities', 'true');
    if (args.includeZoomIdentity) queryParams.append('include_zoom_identity', 'true');
    Network.get(`/users/show_as_json?${queryParams.toString()}`, args);
  },
};
