import Network from 'lib/api/network';
import useClientState from 'composables/client-state';

const { getLocalStorage, setLocalStorage } = useClientState();

export default {
  namespaced: true,

  state() {
    return {
      cursor: 1, // cursor refers to the page in the BE pagination and is completely unrelated to the UI display
      currentPage: 0, // currentPage refers to the page in the FE pagination and corresponds to the groupings of numResultsPerPage size
      fetchingData: true,
      data: [],
      allTags: [],
      settings: {
        showAllTags: true,
        selectedTagIds: [],
        selectedIssueTypeIds: ['missing_role', 'task_overdue', 'poll_overdue', 'strategic_connections_not_selected', 'channels_not_selected'],
        selectedDateRangeValue: '6m',
      },
    };
  },

  getters: {
    // The following are constants that are in getters so as to be accessible elsewhere.
    numResultsPerPage() { return 10; },
    issueTypes() {
      return [
        { id: 'missing_role', name: 'Missing roles', descName: 'Missing roles', value: false },
        { id: 'task_overdue', name: 'Task past due', descName: 'Overdue tasks', value: false },
        { id: 'poll_overdue', name: 'Poll past due', descName: 'Overdue polls', value: false },
        { id: 'strategic_connections_not_selected', name: 'Intros haven\'t been selected by role', descName: 'Unselected intros', value: false },
        { id: 'channels_not_selected', name: 'Channels haven\'t been selected by role', descName: 'Unselected channels', value: false },
      ];
    },
    dateRanges() {
      return [
        { value: '1w', label: 'Last week' },
        { value: '1m', label: 'Last month' },
        { value: '3m', label: 'Last 3 months' },
        { value: '6m', label: 'Last 6 months' },
        { value: '1y', label: 'Last year' },
      ];
    },

    // The following are computed properties
    allDataFetched(state) {
      return !state.cursor;
    },
    resultsForCurrentPage(state, getters) {
      const startIdx = state.currentPage * getters.numResultsPerPage;
      return state.data.slice(startIdx, startIdx + getters.numResultsPerPage);
    },
    currentPageHasEnoughResults(_state, getters) {
      return getters.resultsForCurrentPage.length >= getters.numResultsPerPage;
    },
    needToFetchData(_state, getters) {
      return !(getters.allDataFetched || getters.currentPageHasEnoughResults);
    },
  },

  mutations: {
    addResults(state, { results, cursor }) {
      state.data = state.data.concat(results);
      state.cursor = cursor;
    },

    toggleRemovalPendingStateForIssue(_state, { issue, removalPending }) {
      issue.removalPending = removalPending;
    },

    removeIssue(state, { userId, sessionId, issueId }) {
      // This mutation goes into the data results by finding the result with a user that matches the issue's userId,
      // that user's session that matches the issue's sessionId, and then the issue itself by it's own id.
      // It then removes it from the issues array in the session.
      // If the session now has no more issues, the session is itself removed from the results's sessions array.
      // If that result now has no more sessions, remove the entire result from the data array.
      const resultIdx = state.data.findIndex(result => result.user.id === userId);
      const sessionIdx = state.data[resultIdx].sessions.findIndex(s => s.id === sessionId);
      const issueIdx = state.data[resultIdx].sessions[sessionIdx].issues.findIndex(i => i.id === issueId);
      state.data[resultIdx].sessions[sessionIdx].issues.splice(issueIdx, 1);
      if (state.data[resultIdx].sessions[sessionIdx].issues.length < 1) {
        state.data[resultIdx].sessions.splice(sessionIdx, 1);
        if (state.data[resultIdx].sessions.length < 1) {
          state.data.splice(resultIdx, 1);
        }
      }
    },

    initializeSettings(state) {
      const settingsFromLocalStorage = JSON.parse(getLocalStorage('attentionRequiredEventsSettings'));
      if (settingsFromLocalStorage) {
        // Manually set these values instead of just setting "settings" wholesale in case there are weird values in there
        // leftover from before a code change or whatever the case may be
        const { showAllTags, selectedDateRangeValue, selectedTagIds, selectedIssueTypeIds } = settingsFromLocalStorage;
        if ([true, false].includes(showAllTags)) state.settings.showAllTags = showAllTags;
        if (selectedDateRangeValue) state.settings.selectedDateRangeValue = selectedDateRangeValue;
        if (selectedTagIds) state.settings.selectedTagIds = selectedTagIds;
        if (selectedIssueTypeIds) state.settings.selectedIssueTypeIds = selectedIssueTypeIds;
      }
    },
  },

  actions: {
    fetchData({ state, getters, commit, dispatch }, showLoadingDots = true) {
      // This is a recursive action meant to get our resultsForCurrentPage up to a length of numResultsPerPage
      // The SessionIssuesController#index endpoint will load a non-standard amount of data that may be
      // more or less than the amount we need to populate the current page in the frontend.
      // If it's more, great. If it's less, keep hitting the endpoint until it's enough.
      const { showAllTags, selectedDateRangeValue, selectedTagIds, selectedIssueTypeIds } = state.settings;
      if (showLoadingDots) {
        commit('update', { module: 'reportingAttentionRequired', key: 'fetchingData', value: true }, { root: true });
      }
      return new Promise((_resolve, _reject) => {
        let url = `/onboarding/session_issues?page=${state.cursor}`;
        if (selectedDateRangeValue) url += `&date_range=${selectedDateRangeValue}`;
        if (!showAllTags && selectedTagIds) url += `&tag_ids=${selectedTagIds.join('-')}`;
        if (selectedIssueTypeIds) url += `&issue_types=${selectedIssueTypeIds.join('-')}`;
        Network.get(
          url,
          {
            success: (res) => {
              // Update state with the appropriate data fetched from the controller
              commit('addResults', { results: res.data, cursor: res.cursor });
              // Check to see if we've exhausted the data from the controller or if we have enough resultsForCurrentPage
              if (getters.needToFetchData) {
                // If we haven't, we must keep going
                dispatch('fetchData');
              } else {
                // If we have, we can rest
                commit('update', { module: 'reportingAttentionRequired', key: 'fetchingData', value: false }, { root: true });
              }
            },
            error: (_error) => {
              dispatch('setErrorToast', null, { root: true });
              commit('update', { module: 'reportingAttentionRequired', key: 'fetchingData', value: false }, { root: true });
            },
          },
        );
      });
    },

    fetchTags({ commit, dispatch }) {
      return new Promise((resolve, _reject) => {
        Network.get(
          '/onboarding/tags',
          {
            success: (data) => {
              commit('update', { module: 'reportingAttentionRequired', key: 'allTags', value: data }, { root: true });
              resolve(true);
            },
            error: (_error) => {
              dispatch('setErrorToast', null, { root: true });
              commit('closeModal', null, { root: true });
            },
          },
        );
      });
    },

    applySettings({ commit, dispatch }, settings) {
      // Revert all previously acquired data to defaults.
      commit('update', { module: 'reportingAttentionRequired', key: 'currentPage', value: 0 }, { root: true });
      commit('update', { module: 'reportingAttentionRequired', key: 'cursor', value: 1 }, { root: true });
      commit('update', { module: 'reportingAttentionRequired', key: 'data', value: [] }, { root: true });
      // Set the new settings in vuex state and in localStorage
      commit('update', { module: 'reportingAttentionRequired', key: 'settings', value: settings }, { root: true });
      setLocalStorage('attentionRequiredEventsSettings', JSON.stringify(settings));
      // Fetch new data based on these saved settings
      dispatch('fetchData');
    },

    changePage({ commit, dispatch, getters }, { pageNum }) {
      commit('update', { module: 'reportingAttentionRequired', key: 'currentPage', value: pageNum }, { root: true });
      if (getters.needToFetchData) dispatch('fetchData');
    },

    hideIssue({ state, getters, commit, dispatch }, { user, session, issue }) {
      commit('toggleRemovalPendingStateForIssue', { issue, removalPending: true });
      return new Promise((_resolve, _reject) => {
        Network.post(
          `/onboarding/session_issues/${issue.id}/hide_issue`,
          null,
          {
            success: (_data) => {
              commit('removeIssue', { userId: user.id, sessionId: session.id, issueId: issue.id });
              // If we've successfully removed the issue, we need to assess if we've removed an entire user.
              // If there are sufficient fetched results in data, it will automatically populate since
              // the resultsForCurrentPage getter is a computed property, but we may need to hit the
              // controller again if there isn't enough in state.
              if (getters.needToFetchData) {
                dispatch('fetchData', false);
              } else if (getters.allDataFetched && !getters.resultsForCurrentPage.length && state.data.length) {
                // If you end up in a situation where you have removed an issue and
                // 1. there is no more data to fetch from the controller
                // 2. there are no longer any users left on the page
                // 3. there are still pages with users left previous to this page
                // You need to navigate back a page
                dispatch('changePage', { pageNum: state.currentPage - 1 });
              }
            },
            error: (_error) => {
              dispatch('setErrorToast', null, { root: true });
              commit('toggleRemovalPendingStateForIssue', { issue, removalPending: false });
            },
          },
        );
      });
    },
  },
};
