// Helper methods

const clearNewEvents = (state) => {
  if (state.events) {
    for (const event of state.events) {
      event.new = false;
    }
  }
};

const updatePresetAndEvent = (state, {
  presetID, presetKeys, presetValues, presetEventID, presetEventKey, presetEventValue,
}) => {
  for (const preset of state.presets) {
    if (preset.id === presetID) {
      if (presetKeys) {
        for (const idx in presetKeys) {
          const key = presetKeys[idx];
          const value = presetValues[idx];
          preset[key] = value;
        }
      }

      if (presetEventID) {
        for (const event of preset.events) {
          if (event.id === presetEventID) {
            event[presetEventKey] = presetEventValue;
            break;
          }
        }
      }
      break;
    }
  }
};

const updatePreset = (state, preset, presetKeys, presetValues) => {
  updatePresetAndEvent(state, {
    presetID: preset.id,
    presetKeys,
    presetValues,
  });
};

const updatePresetEvent = (state, presetEvent, presetEventKey, presetEventValue) => {
  updatePresetAndEvent(state, {
    presetID: presetEvent.preset.id,
    presetEventID: presetEvent.id,
    presetEventKey,
    presetEventValue,
  });
};

const addEvent = (state, event) => {
  const idx = _.findIndex(state.events, e => e.id === event.id && e.type === event.type);
  if (idx >= 0) {
    state.events[idx] = event;
    state.events[idx].new = true;
  } else {
    state.events.push(event);
    event.new = true;
  }
};

const addEvents = (state, events) => {
  for (const event of events) {
    addEvent(state, event);
  }
};

const deactivateEvents = (state, eventIDs) => {
  for (const event of state.events) {
    if (_.includes(eventIDs, event.id) && event.type === 'OnboardingScheduleEvent') {
      event.active = false;
    }
  }
};

const removePresetEvent = (state, presetEvent) => {
  const idx = _.findIndex(state.events, e => e.id === presetEvent.id && e.type === presetEvent.type);
  if (idx >= 0) {
    state.events.splice(idx, 1);
  }
};

const updateInvolvedRoles = (state, involvedRoles) => {
  for (const role of involvedRoles) {
    const idx = _.findIndex(state.involvedRoles, r => r.id === role.id);
    if (idx >= 0) {
      state.involvedRoles[idx] = role;
    } else {
      state.involvedRoles.push(role);
      role.new = true;
    }
  }
};


// Exported methods

export default {
  // Add an entire preset

  addPreset(state, { preset, events, involvedRoles, newRoles }) {
    state.allRoles = state.allRoles.concat(newRoles);
    preset.selected = true;
    preset.edited = false;

    for (const presetEvent of preset.events) {
      presetEvent.in_use = true;
    }

    clearNewEvents(state);
    addEvents(state, events);

    updateInvolvedRoles(state, involvedRoles);
  },


  // Remove an entire preset

  removePreset(state, { preset, eventIDs }) {
    preset.selected = false;
    preset.edited = false;

    for (const presetEvent of preset.events) {
      presetEvent.in_use = false;
    }

    clearNewEvents(state);
    deactivateEvents(state, eventIDs);
  },


  // Add a single preset event

  addPresetEvent(state, { presetEvent, day }) {
    clearNewEvents(state);

    const copy = _.cloneDeep(presetEvent);
    copy.days_later = day;

    addEvent(state, copy);
    updatePresetEvent(state, presetEvent, 'in_use', true);
  },

  addPresetEventSuccessful(state, { presetEvent, scheduleEvent, involvedRoles, newRoles }) {
    state.allRoles = state.allRoles.concat(newRoles);
    removePresetEvent(state, presetEvent);
    addEvent(state, scheduleEvent);

    updatePreset(state, presetEvent.preset, ['selected', 'edited'], [true, true]);

    updateInvolvedRoles(state, involvedRoles);
  },

  addPresetEventFailed(state, presetEvent) {
    removePresetEvent(state, presetEvent);
    updatePresetEvent(state, presetEvent, 'in_use', false);
  },

  updatePresetEvent(state, { key, value }) {
    state.event[key] = value;
  },

  updatePresetEventRecipientType(state, value) {
    // Setting channel_send is ultimately redundant since the input for it in `_form-recipient.vue`
    // is always "true" when the accordion is set to 'channel', but it's nice to do
    if (value === 'role' && !state.event.role) {
      const role = state.allRoles.find(r => r.purpose === 'primary');
      state.event.role = role;
      state.event.channel_send = false;
      state.event.batch_channel_send = false;
    } else if (value === 'channel') {
      state.event.role = null;
      state.event.channel_send = true;
      state.event.batch_channel_send = false;
      state.event.event_type = 'messaging';
    } else if (value === 'cohortChannel') {
      state.event.role = null;
      state.event.channel_send = false;
      state.event.batch_channel_send = true;
      state.event.event_type = 'messaging';
    }
  },

  updateScheduleEvent(state, { event, key, value }) {
    event[key] = value;
  },

  rescheduleScheduleEvent(state, { event, day }) {
    event.days_later = day;
  },

  rescheduleScheduleEventSuccessful(state, event) {
    if (event.preset) {
      updatePreset(state, event.preset, ['edited'], ['true']);
    }
  },

  rescheduleScheduleEventFailed(state, { event, oldDaysLater }) {
    event.days_later = oldDaysLater;
  },


  // Remove a single schedule event

  deactivateScheduleEvent(state, event) {
    event.active = false;
    clearNewEvents(state);
  },

  deactivateScheduleEventSuccessful(state, event) {
    if (event.preset != null) {
      let stillInUse = false;
      for (const e of state.events) {
        if (e.active && e.preset != null && e.preset.id === event.preset.id) {
          stillInUse = true;
          break;
        }
      }

      updatePresetAndEvent(state, {
        presetID: event.preset.id,
        presetKeys: ['selected', 'edited'],
        presetValues: [stillInUse, stillInUse],
        presetEventID: event.preset.event_id,
        presetEventKey: 'in_use',
        presetEventValue: false,
      });
    }
  },

  deactiveScheduleEventFailed(state, event) {
    event.active = true;
  },
};
