const emit = (vnode, name, data) => {
  const handlers = (vnode.data && vnode.data.on)
    || (vnode.componentOptions && vnode.componentOptions.listeners);

  if (handlers && handlers[name]) {
    handlers[name].fns(data);
  } else {
    // It appears as though Vue3 affects where these functions live. This worked for me.
    const context = vnode.ctx?.ctx;
    context.change(data);
  }
};

const updateDatepicker = (el, binding, vnode) => {
  const $el = $(el);
  if ($el.attr('datepicker') == null) {
    // activate datepicker with right args

    let args = {
      format: 'mm/dd/yyyy',
      daysOfWeekDisabled: '0,6',
      orientation: 'bottom auto',
      autoclose: 'true',
      highlightedDates: [],
      outlinedDates: [],
      keyContent: null,
      clearBtn: false,
    };

    if (binding.value != null && typeof (binding.value) === 'object') {
      args = { ...args, ...binding.value };
    }

    $el.on('change', () => {
      emit(vnode, 'change', $el.val());
    });

    $el.datepicker('destroy');
    $el.datepicker({
      ...args,
      container: 'content.donut',
      beforeShowDay(date) { // eslint-disable-line func-names
        const curDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        let addedClasses = '';
        if (args.highlightedDates.indexOf(curDate) !== -1) {
          addedClasses = addedClasses.concat('highlighted-date');
        }
        if (args.outlinedDates.indexOf(curDate) !== -1) {
          addedClasses = addedClasses.concat(' outlined-date');
        }
        return { classes: addedClasses };
      },
    }).on('show', (e) => { // eslint-disable-line func-names
      if (!args.keyContent) { return; }
      const $key = $('.js-datepicker-key');
      if (!$key.length) {
        $('.datepicker-dropdown').append(args.keyContent);
      } else {
        // Prevent the datepicker script from hiding this element on clicks after the first
        $key.show();
      }
    });
  }
};

export default {
  mounted(el, binding, vnode) {
    updateDatepicker(el, binding, vnode);
  },

  updated(el, binding, vnode) {
    updateDatepicker(el, binding, vnode);
  },
};
