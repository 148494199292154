import URLHelper from './url-helper';

export default {
  presetCategories(args = {}) {
    return URLHelper.build('/onboarding/preset_categories', args);
  },

  presetCategory(category, args = {}) {
    return URLHelper.build(`/onboarding/preset_categories/${category.id}`, args);
  },

};
