import URLHelper from './url-helper';

export default {
  newWatercooler(args = {}) {
    return URLHelper.build('/channel_programs/new?preview=watercooler', args);
  },

  watercooler(watercooler, args = {}) {
    // Currently same as editWatercooler; no show route for /watercooler
    return URLHelper.build(`/watercooler/${watercooler.id}/edit`, args);
  },

  editWatercooler(watercooler, args = {}) {
    return URLHelper.build(`/watercooler/${watercooler.id}/edit`, args);
  },
};
