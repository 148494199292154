export default {
  updateCelebrationSchedules(state, value) {
    state.celebrationSchedules = value;
  },

  updateCelebrationSchedule(state, value) {
    const user = state.celebrationSchedules.find(s => s.user.id === value.user_id);
    user[value.schedule.celebrationType] = value.schedule;
  },

  updateCustomSchedules(state, value) {
    state.customSchedules = value;
  },
};
