import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      flow_type: null,
      flowTypeOptions: null,
      sidebarLoading: false,
    };
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
  },

  actions: {
    togglePresetForTag({ commit, dispatch }, { tag, preset, channelId }) {
      return new Promise((resolve) => {
        commit('update', { key: 'sidebarLoading', value: true });
        Network.put(
          `/onboarding/tags/${tag.id}/presets/${preset.id}`,
          { removing: preset.selected, channel_id: channelId },
          {
            success: (data) => {
              if (preset.selected) {
                commit('removePreset', {
                  preset,
                  eventIDs: data,
                }, { root: true });
                dispatch('setSuccessToast', `${preset.name} was removed from your Journey`, { root: true });
              } else {
                commit('addPreset', {
                  preset,
                  events: data.events,
                  involvedRoles: data.involved_roles,
                  newRoles: data.new_roles,
                }, { root: true });
                dispatch('setSuccessToast', `${preset.name} was added to your Journey`, { root: true });
                if (data.new_roles?.length) {
                  commit('updateModal', {
                    visible: true,
                    subcomponent: 'onboarding-events-modals-new-roles',
                    data: { newRoles: data.new_roles, preset },
                  }, { root: true });
                }
              }
            },
            complete: () => {
              commit('update', { key: 'sidebarLoading', value: false });
            },
          },
        );
      });
    },
  },
};
