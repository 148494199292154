import URLHelper from './url-helper';

export default {
  presetEvents(preset, args = {}) {
    return URLHelper.build(`/onboarding/presets/${preset.id}/events`, args);
  },

  presetEvent(event, args = {}) {
    return URLHelper.build(`/onboarding/presets/${event.preset.id}/events/${event.id}`, args);
  },
};
