import API from 'lib/api/api';
import Network from 'lib/api/network';

export default {

  rescheduleScheduleEvent({ commit }, { event, day }) {
    const oldDaysLater = event.days_later;

    commit('rescheduleScheduleEvent', { event, day });
    Donut.API.updateEvent(event.id, {
      data: { days_later: day },
      success: (data) => {
        this.commit('rescheduleScheduleEventSuccessful', event);
      },
      error: (error) => {
        this.commit('rescheduleScheduleEventFailed', { event, oldDaysLater });
      },
    });
  },

  deactivateScheduleEvent({ commit }, event) {
    this.commit('deactivateScheduleEvent', event);
    Donut.API.updateEvent(event.id, {
      data: { active: false },
      success: (data) => {
        this.commit('deactivateScheduleEventSuccessful', event);
      },
      error: (error) => {
        this.commit('deactivateScheduleEventFailed', event);
      },
    });
  },

  addPresetEvent({ commit, dispatch }, { tag, presetEvent, day, channelId }) {
    commit('addPresetEvent', { presetEvent, day });
    API.Presets.addPresetEvent(tag, presetEvent, day, channelId, {
      success: (data) => {
        commit('addPresetEventSuccessful', {
          presetEvent,
          scheduleEvent: data.event,
          involvedRoles: data.involved_roles,
          newRoles: data.new_roles,
        });
        dispatch('setSuccessToast', 'Added the message to your template!');
        if (data.new_roles?.length) {
          commit('updateModal', {
            visible: true,
            subcomponent: 'onboarding-events-modals-new-roles',
            data: { newRoles: data.new_roles },
          });
        }
      },
      error: (error) => {
        commit('addPresetEventFailed', presetEvent);
        dispatch('setErrorToast', 'Uh oh, something went wrong adding that message!');
      },
    });
  },

  openEventMoveModal({ commit }, event) {
    const tempEvent = { ...event };
    commit('update', { key: 'event', value: tempEvent });
    commit('updateModal', {
      visible: true,
      size: 'xl of-v',
      dismissable: true,
      subcomponent: 'onboarding-events-modals-move',
      data: { event },
    });
  },

  moveOnboardingEvent({ commit, dispatch, state }, { updatedEvent, event, flowType }) {
    Network.put(`/${flowType?.routeResource || 'onboarding'}/events/${updatedEvent.id}`, {
      onboarding_schedule_event: updatedEvent,
    }, {
      success: () => {
        const index = state.events.findIndex((e) => e.id === event.id);
        state.events[index] = updatedEvent;
        dispatch('setSuccessToast', 'Successfully moved the message!');
      },
      error: () => {
        dispatch('setErrorToast', 'Uh oh, something went wrong moving that message!');
      },
      complete: () => {
        commit('updateModal', { visible: false });
      },
    });
  },

  openEventDeleteModal({ commit }, eventId) {
    commit('updateModal', {
      visible: true,
      size: 'sm',
      dismissable: true,
      subcomponent: 'onboarding-events-modals-delete',
      data: { eventId },
    });
  },

  deleteOnboardingEvent({ commit, dispatch, state }, { eventId, flowType }) {
    Network.put(`/${flowType?.routeResource || 'onboarding'}/events/${eventId}`, {
     onboarding_schedule_event: { active: false },
    }, {
      success: () => {
        const index = state.events.findIndex((event) => event.id === eventId);
        commit('updateScheduleEvent', { event: state.events[index], key: 'active', value: false });
        dispatch('setSuccessToast', 'Successfully deleted the message!');
      },
      error: () => {
        dispatch('setErrorToast', 'Uh oh, something went wrong deleting that message!');
      },
      complete: () => {
        commit('updateModal', { visible: false });
      },
    });
  },

  duplicateOnboardingEvent({ commit, dispatch, state }, { eventId, tagId, flowType }) {
    Network.post(`/${flowType.routeResource}/tags/${tagId}/events/${eventId}/duplicate_as_json`, {}, {
      success: (response) => {
        state.events.push(response.schedule_event);
        dispatch('setSuccessToast', 'Successfully duplicated the message!');
      },
      error: () => {
        dispatch('setErrorToast', 'Uh oh, something went wrong duplicating that message!');
      },
    });
  },

  sendTestOnboardingEvent({ commit, dispatch, state }, { eventId, tagId, flowType }) {
    Network.post(`/${flowType.routeResource}/tags/${tagId}/events/${eventId}/send_test`, {}, {
      success: () => {
        dispatch('setSuccessToast', 'Successfully sent yourself a test version of this message!');
      },
      error: () => {
        dispatch('setErrorToast');
      },
    });
  },
};
