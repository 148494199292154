import API from 'lib/api/api';

export default {
  getAssetImport({ commit }, { importId, programId, programType }) {
    return new Promise((resolve, reject) => {
      API.AssetImports.getAssetImport(importId, { programId, programType }, {
        success: (data) => {
          commit('updateAssetImport', data);
          resolve(true);
        },
        error: (error) => {
          resolve(false);
        },
      });
    });
  },
};
