import URLHelper from './url-helper';

export default {
  tagPresets(tag, args = {}) {
    let tagID;
    if (tag != null) tagID = tag.id;

    return URLHelper.build(`/onboarding/tags/${tagID}/presets`, args);
  },

  tagPreset(tag, preset, args = {}) {
    let tagID;
    if (tag != null) tagID = tag.id;

    let presetID;
    if (preset != null) presetID = preset.id;

    return URLHelper.build(`/onboarding/tags/${tagID}/presets/${presetID}`, args);
  },
};
