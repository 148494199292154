import URLHelper from './url-helper';

export default {
  templates(args = {}) {
    return URLHelper.build('/templates', args);
  },

  channelPrograms(args = {}) {
    return URLHelper.build('/all_channels', args);
  },
};
