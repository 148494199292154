import API from 'lib/api/api';
import Network from 'lib/api/network';
import _ from 'lodash';

export default {
  inviteUsersToChannels({ state, commit, dispatch }, { args, program }) {
    const fromSendNow = args.source.startsWith('send_now');
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      API.Channels.inviteUsersToChannels(
        {
          success: (data) => {
            if (data.num_invited) { // Only false if they somehow made the request with no users.
              if (_.isNumber(program.num_participants)) {
                program.num_participants += data.num_added;
              }
              commit('updateChannelProgram', { program });
              if (fromSendNow) {
                dispatch('channelProgramsSendNow/sendNow', { program, source: args.source });
              } else {
                dispatch('setSuccessToast', 'Success! More people have been added to your channel!');
                dispatch('closeModal');
              }
            }
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
          complete: () => {
            if (!fromSendNow) {
              commit('update', { key: 'formSubmitted', value: false });
            }
          },
        },
        args,
      );
    });
  },

  sendTextToChannel({ commit, dispatch }, { channel, text, successAction }) {
    return new Promise((resolve, reject) => {
      Network.post(
        `/channels/${channel.id}/post_in_slack`,
        { text },
        {
          success: (data) => {
            dispatch('setSuccessToast', `Successfully posted in ${channel.name}!`);
            if (successAction) dispatch(successAction);
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },
};
