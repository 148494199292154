const defaults = {
  visible: false,
  subcomponent: null,
  size: null, // Options are null (the smallest), "md", "lg", "xl", "xxl", "xxxl"
  onClose: null,
  dismissable: true,
  confirmOnCancel: false,
  data: null,
  cancelAction: null,
  cancelMutation: null,
  cancelArgs: null,
  scrollToTopOnChange: false,
};

export default {
  updateModal(state, value) {
    state.modal = Object.assign({}, defaults, value);
  },

  closeModal(state) {
    state.modal = defaults;
  },

  updateModalData(state, value) {
    state.modal = {
      ...state.modal,
      data: Object.assign({}, state.modal.data, value),
    };
  },
};
