export default {
  namespaced: true,

  state() {
    return {
      visible: false,
      title: null,
      data: {},
      ltr: true,
    };
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },

    closeDrawer(state) {
      state.visible = false;
      state.title = null;
      state.data = {};
    },

    updateDrawerData(state, { key, value }) {
      state.data[key] = value;
    },

    updateDrawer(state, value) {
      const { visible, title, data } = value;
      state.visible = visible;
      state.title = title;
      state.data = data;
    },
  },
};
