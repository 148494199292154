import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      questions: null,
      primarySubmissions: null,
      nonPrimarySubmissions: null,
      primaryRoleName: null,
      nonPrimaryRoleName: null,
      tooltips: {},
      idForSubmissionWithOpenMatchingMenu: null,
      responsesTableEl: null,
      pendingMatches: {},
    };
  },

  getters: {
    usingMatching(state) {
      return !!state.nonPrimarySubmissions;
    },

    numColumns(state, getters) {
      let num = state.questions.length + 1; // 1 column for every question, plus 1 for 'Participant'
      if (getters.usingMatching) num += 1; // Add another column for 'Role' if using matching
      return num;
    },

    tableWidthStyle(state) {
      if (state.responsesTableEl) {
        const tablePosition = state.responsesTableEl.getBoundingClientRect();
        const tableWidth = tablePosition.right - tablePosition.left;
        // We only want to stretch it if its over the width of the rest of 'main', which is 960px (see layout.scss)
        // Were we not to do this, the actions buttons would be floated all the way to the right even with a small table
        if (tableWidth > 960) {
          const screenWidth = window.innerWidth;
          const extendedTableWidth = screenWidth - tablePosition.left;
          return { width: `${extendedTableWidth}px` };
        }
      }
    },
  },

  mutations: {
    establishTooltip(state, { ref, refName, value }) {
      if (ref && ref.offsetWidth < ref.scrollWidth) {
        state.tooltips[refName] = value;
      }
    },

    matchSubmissions(state, { primaryId, nonPrimaryId }) {
      // nonPrimaryId can be null, in the case of breaking matches
      const primary = state.primarySubmissions.find(sub => sub.id === primaryId);
      primary.matched_submission_id = nonPrimaryId;
      state.pendingMatches[primaryId] = {
        matched_submission_id: nonPrimaryId,
        session_id: primary.session_ids[0], // It's safe to assume that primary submissions will only ever be attached to 1 session
      };
    },

    clearPendingMatches(state) {
      state.pendingMatches = {};
    },
  },

  actions: {
    approveMatches({ state, commit, dispatch }, { url }) {
      commit('update', { key: 'formSubmitted', value: true }, { root: true });
      return new Promise((_resolve) => {
        const matches = Object.keys(state.pendingMatches).map((primarySubmissionId) => ({ primary_submission_id: primarySubmissionId, ...state.pendingMatches[primarySubmissionId] }));
        Network.put(
          url,
          { matches },
          {
            success: () => {
              dispatch('setSuccessToast', 'Saved matches!', { root: true });
              commit('clearPendingMatches');
            },
            error: () => {
              dispatch('setErrorToast', null, { root: true });
            },
            complete: () => {
              commit('update', { key: 'formSubmitted', value: false }, { root: true });
            },
          },
        );
      });
    },
  },
};
