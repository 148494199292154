import Network from './network';

export default {
  updateOwnership(args, callbacks) {
    Network.put(
      `/coffee/${args.record_id}/update_ownership_as_json`,
      args,
      callbacks,
    );
  },

  destroyAllGhosts(configId, args, callbacks) {
    Network.delete(
      `/coffee/${configId}/destroy_ghosts_as_json`,
      args,
      callbacks,
    );
  },

  getPeopleMet(args) {
    Network.get('/coffee/show_people_met_as_json', args);
  },
};
