export default {
  build(path, args = {}) {
    let constructedURL = path;

    Object.entries(args).forEach(([key, value]) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);

      let connector;
      if (constructedURL.indexOf('?') > 0) {
        connector = '&';
      } else {
        connector = '?';
      }

      constructedURL += `${connector}${encodedKey}=${encodedValue}`;
    });

    return constructedURL;
  },
};
