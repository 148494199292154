import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      integratablePrograms: null,
      celebrationSchedules: {},
      tags: [],
    };
  },

  getters: {
    defaultProgramType() {
      const location = window.location.pathname;
      if (location.startsWith('/coffee')) return 'intros';
      if (location.startsWith('/celebrations')) return 'celebrations';
      if (location.startsWith('/onboarding')) return 'onboarding';
      return null;
    },

    programTypes(_state, getters) {
      const programTypes = ['celebrations'];
      if (getters.showOnboarding) { programTypes.push('onboarding'); }
      programTypes.push('intros');
      return programTypes;
    },

    anyProgramsSelected(state, getters) {
      // Checks all programs for all types to see if any of them have any of their settings turned on
      return getters.programTypes.some((programType) => {
        const programs = state.integratablePrograms[programType];
        return programs.some(program => getters.programIsSelected(program.hris_sync_data));
      });
    },

    programIsSelected: () => (settings) => {
      // settings here refers to the hris_sync_data object on all the integratablePrograms
      // Example settings for a celebrations program { birthday: true, anniversary: false }
      // Example settings for onboarding program { new_hires: true, default_tag_ids: [1, 44] }
      // Example settings for intros program { category_group_selection: true }
      const settingValues = Object.values(settings);
      // If any of the values are a boolean of true, the whole thing is selected.
      return settingValues.some(val => val === true);
    },

    programSettings: state => ({ programType, idx }) => state.integratablePrograms[programType][idx].hris_sync_data,

    showOnboarding(_state, _getters, rootState) {
      const { currentUser, currentTeam } = rootState;
      return currentUser.onboarding_unrestricted;
    },
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },

    updateProgramSettingValue(state, { programType, idx, settingsKey, value }) {
      const settings = state.integratablePrograms[programType][idx].hris_sync_data;
      settings[settingsKey] = value;
    },

    updateCelebrationSchedules(state, { programId, schedules }) {
      state.celebrationSchedules[programId] = schedules;
    },
  },

  actions: {
    track(_context, { eventName, properties }) {
      const fullEventName = `HRIS Manage Programs: ${eventName}`;
      window.mixpanel.track(fullEventName, properties);
    },

    toggleProgramSetting({ getters, commit }, { programType, idx, settingsKey }) {
      // Used for the boolean checkbox settings. Find current value of the setting and flip it
      const settings = getters.programSettings({ programType, idx });
      const value = !settings[settingsKey];
      commit('updateProgramSettingValue', { programType, idx, settingsKey, value });
    },

    toggleProgram({ getters, commit }, { programType, idx, value }) {
      // Turn the value of every boolean setting to true or false
      const settings = getters.programSettings({ programType, idx });
      Object.keys(settings).forEach((settingsKey) => {
        if ([true, false].includes(settings[settingsKey])) {
          // If the value isn't a boolean, let it be
          commit('updateProgramSettingValue', { programType, idx, settingsKey, value });
        }
      });
    },

    openModal({ state, getters, commit, dispatch }, { fromInitialSync }) {
      commit('updateModal', {
        visible: true,
        subcomponent: 'hris-connect-modals-manage-programs',
        size: 'xl',
      }, { root: true });
      // If a user closes the modal and reopens, we want it to always default to reflect reality,
      // not what they may have previously selected and not submitted. Clear the cache!
      commit('update', { key: 'integratablePrograms', value: null });
      dispatch('getIntegratablePrograms').then(() => {
        const hasIntegratablePrograms = getters.programTypes.some(programType => state.integratablePrograms[programType].length > 0);
        if (hasIntegratablePrograms) {
          if (fromInitialSync) dispatch('turnOnRelevantPrograms');
          dispatch('getSchedulesForCelebrationsPrograms');
          if (getters.showOnboarding) dispatch('getTags');
        } else {
          commit('updateModal', {
            visible: true,
            subcomponent: 'hris-connect-modals-manage-programs-empty',
          }, { root: true });
        }
        window.mixpanel.track('HRIS Connect: Opened manage programs modal', { fromInitialSync, hasIntegratablePrograms });
      });
    },

    turnOnRelevantPrograms({ state, rootState, getters, dispatch }) {
      if (getters.defaultProgramType === 'celebrations' || getters.defaultProgramType === 'intros') {
        // If we are viewing this modal from the edit page of a channel program,
        // we should assume they want to import all the data they can and turn it on by default.
        // There should always be a `celebrationProgram` / `coffeeConfiguration` in rootState in this situation.
        let idx;
        let objectName;
        // eslint-disable-next-line default-case
        switch (getters.defaultProgramType) {
          case 'celebrations':
            objectName = 'celebrationProgram';
            break;
          case 'intros':
            objectName = 'coffeeConfiguration';
            break;
        }
        state.integratablePrograms[getters.defaultProgramType].forEach((program, i) => {
          if (program.id === rootState[objectName].id) {
            idx = i;
          }
        });
        dispatch('toggleProgram', {
          programType: getters.defaultProgramType,
          idx,
          value: true,
        });
      } else if (getters.defaultProgramType === 'onboarding' && getters.showOnboarding) {
        // If we are viewing this modal from the batches index page, turn on onboarding
        dispatch('toggleProgram', {
          programType: 'onboarding',
          idx: 0,
          value: true,
        });
      } else {
        // Else, if we are doing this from the HRIS page itself, turn on everything by default
        getters.programTypes.forEach((programType) => {
          state.integratablePrograms[programType].forEach((_program, idx) => {
            dispatch('toggleProgram', {
              programType,
              idx,
              value: true,
            });
          });
        });
      }
    },

    getIntegratablePrograms({ commit, dispatch }) {
      return new Promise((resolve, _reject) => {
        Network.get('/hris/integratable_programs_as_json', {
          success: ({ integratablePrograms }) => {
            commit('update', { key: 'integratablePrograms', value: integratablePrograms });
            resolve(true);
          },
          error: (_error) => {
            dispatch('setErrorToast', null, { root: true });
            resolve(false);
          },
        });
      });
    },

    getSchedulesForCelebrationsPrograms({ state, rootState, commit, dispatch }) {
      // For every potentially integratable celebrations program, we want to get the data on their schedules.
      // This is already done async via the getCelebrationSchedules action for the single relevant program
      // when visiting the the CelebrationProgram#edit page.
      // We will perform that same action for every program in our integratablePrograms.celebrations
      state.integratablePrograms.celebrations.forEach((program) => {
        if (rootState.celebrationProgram && rootState.celebrationSchedules && rootState.celebrationProgram.id === program.id) {
          // If a `celebrationProgram` and `celebrationSchedules` exist in the root state,
          // that's the aforementioned single program on the CelebrationProgram#edit page.
          // No need to re-fetch, just add it to the list in the hrisManagePrograms module
          commit('updateCelebrationSchedules', {
            programId: program.id,
            schedules: rootState.celebrationSchedules,
          });
        } else {
          // Otherwise, we need to fetch through the action below
          dispatch('getCelebrationSchedules', {
            celebrationProgramId: program.id,
            forHrisSyncData: true,
          }, { root: true }).then((data) => {
            commit('updateCelebrationSchedules', {
              programId: program.id,
              schedules: data,
            });
          });
        }
      });
    },

    getTags({ commit }) {
      return new Promise((resolve, _reject) => {
        Network.get('/onboarding/tags', {
          success: (data) => {
            commit('update', { key: 'tags', value: data });
          },
          error: (error) => {
            resolve(error);
          },
        });
      });
    },

    createSyncables({
      state,
      rootState,
      getters,
      commit,
      dispatch,
    }) {
      const params = {};
      getters.programTypes.forEach((programType) => {
        params[programType] = {};
        state.integratablePrograms[programType].forEach((program) => {
          params[programType][program.id] = program.hris_sync_data;
        });
      });
      commit('update', { key: 'formSubmitted', value: true }, { root: true });
      return new Promise((resolve, _reject) => {
        Network.post(
          `/hris/${rootState.hrisConnect.identity.id}/create_syncables_as_json`,
          { hris_syncables: params },
          {
            success: (_data) => {
              commit('updateModal', {
                visible: true,
                subcomponent: 'hris-connect-modals-manage-programs-success',
              }, { root: true });
              commit('startConfetti', null, { root: true });
            },
            error: (_error) => {
              dispatch('setErrorToast', null, { root: true });
              commit('closeModal', null, { root: true });
              resolve(false);
            },
            complete: () => {
              commit('update', { key: 'formSubmitted', value: false }, { root: true });
            },
          },
        );
      });
    },
  },
};
