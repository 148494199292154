import Network from 'lib/api/network';

export default {
  destroyUserIntegration({ state, dispatch, commit }, { provider }) {
    return new Promise((resolve, reject) => {
      Network.delete(
        '/users/integrations/destroy_as_json',
        { provider },
        {
          success: (data) => {
            dispatch('setSuccessToast', 'Your integration has been unlinked.');
            commit('unlinkIntegration', provider);
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },
};
