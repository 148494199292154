import * as moment from 'moment';

export default {
  recordUsedPairingAnnouncement(state) {
    if (state.currentUser != null) {
      if (state.currentUser.progress == null) {
        state.currentUser.progress = {};
      }
      state.currentUser.progress.has_used_pairing_announcement = true;
    }
  },

  updateCoffeeMeeting(state, { coffeeMeeting, originalState }) {
    const { coffeeMeetings, yourMeetingStats } = state;

    if (coffeeMeetings != null) {
      const index = _.findIndex(coffeeMeetings, m => m.id === coffeeMeeting.id);
      if (index >= 0) {
        state.coffeeMeetings[index] = coffeeMeeting;
      }
    }

    // TODO: We want to reevaluate this later
    // if (yourMeetingStats != null) {
    //   const newValueOriginalState = (yourMeetingStats[originalState] || 1) - 1;
    //   state.yourMeetingStats[originalState] = newValueOriginalState;

    //   const newValueNewState = (yourMeetingStats[coffeeMeeting.state] || 0) + 1;
    //   state.yourMeetingStats[coffeeMeeting.state] = newValueNewState;
    // }
  },

  updateMeetingTiming(state, payload) {
    const momentFormat = 'ddd, MMM D, YYYY';
    const cc = state.coffeeConfiguration;
    cc[payload.key] = payload.value;

    const meetingExternallyChanged = () => {
      if (cc.calendar_scheduling_selection === 'fixed-meeting') {
        state.meetingExternallyChangedTime = Date.now();
      }
    };

    if (cc.period === 0) {
      // If there are multiple intros per week, set the fixed meeting time to the same day as the next intro
      if (cc.next_fixed_meeting_on !== cc.next_occurrence_on) {
        cc.next_fixed_meeting_on = cc.next_occurrence_on;
        meetingExternallyChanged();
      }
    } else if (
      !cc.next_fixed_meeting_on
      || moment(cc.next_fixed_meeting_on, momentFormat).isAfter(moment(cc.next_occurrence_on, momentFormat).add(cc.period - 1, 'd'))
      || moment(cc.next_fixed_meeting_on, momentFormat).isBefore(moment(cc.next_occurrence_on, momentFormat))
    ) {
      // If there is no fixed meeting date,
      // OR if previously set next meeting is after the newly set intro date + frequency period,
      // OR if the next intro date is set after the fixed meeting date
      // Set fixed meeting to one day after the next intro date.
      let nextMeetingDate = moment(cc.next_occurrence_on, momentFormat).add(1, 'd');
      if (nextMeetingDate.weekday() === 6) {
        // If next meeting is on Saturday, select Monday instead (it shouldn't be possible for Sunday to be returned)
        nextMeetingDate = nextMeetingDate.add(1, 'week').isoWeekday(1);
      }
      cc.next_fixed_meeting_on = nextMeetingDate.format(momentFormat);
      meetingExternallyChanged();
    }

    if (!cc.next_fixed_meeting_time) {
      // If there is no fixed meeting time, set it to be the same as the intro time
      cc.next_fixed_meeting_time = moment(`${cc.occurrence_hour}:00`, 'H:mm').format('H:mm');
    }
    if (cc.next_fixed_meeting_on === cc.next_occurrence_on) {
      const nextIntroTime = moment(`${cc.occurrence_hour}:00`, 'H:mm');
      const nextFixedMeetingTime = moment(cc.next_fixed_meeting_time, 'H:mm').subtract(30, 'm');
      // If intro time is after fixed meeting time, set fixed meeting time to 30 minutes after the intro time
      if (nextFixedMeetingTime.isBefore(nextIntroTime)) {
        cc.next_fixed_meeting_time = nextIntroTime.add(30, 'm').format('H:mm');
        meetingExternallyChanged();
      }
    }
  },

  updateCalendarSchedulingSelection(state, value) {
    // Mounting the accordion selector triggers this selector before anything is
    // selected. 'groups-choose-meeting-time', 'fixed-meeting', and 'no-scheduling'
    // are the only viable values, don't do anything on initial 'null' run.
    if (value) {
      let settingsRecord;
      if (state.meetingIntegrationSettings) {
        settingsRecord = 'meetingIntegrationSettings';
      } else {
        settingsRecord = 'coffeeConfiguration';
      }
      state[settingsRecord].calendar_scheduling_selection = value;
      if (value !== 'groups-choose-meeting-time' && state[settingsRecord].video_platform === 'meet_and_teams') {
        state[settingsRecord].video_platform = 'none';
        state.videoConferencingExternallyChangedTime = Date.now();
      }
    }
  },

  updateVideoConferencingSelection(state, value) {
    let settingsRecord;
    if (state.meetingIntegrationSettings) {
      settingsRecord = 'meetingIntegrationSettings';
    } else {
      settingsRecord = 'coffeeConfiguration';
    }
    state[settingsRecord].video_platform = value;
  },

  updateConfigCustomMessage(state, value) {
    state.coffeeConfiguration.intro_message = value;
    state.coffeeConfiguration.default_intro_type = 'custom';
  },
};
