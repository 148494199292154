import Presets from './presets';
import PresetBundles from './preset-bundles';
import Tasks from './tasks';
import Users from './users';
import UserDetails from './user_details';
import Assets from './assets';
import CoffeeConfigurations from './coffee_configurations';
import CoffeeMeetings from './coffee_meetings';
import Teams from './teams';
import Share from './share';
import Payment from './payment';
import Watercoolers from './watercoolers';
import WatercoolerPrompts from './watercooler_prompts';
import WatercoolerPromptAdjustments from './watercooler_prompt_adjustments';
import WatercoolerPacks from './watercooler_packs';
import WatercoolerPacksPrompts from './watercooler_packs_prompts';
import WatercoolerPacksWatercoolers from './watercooler_packs_watercoolers';
import Reporting from './reporting';
import ServiceAccounts from './service_accounts';
import Channels from './channels';
import ChannelPrograms from './channel_programs';
import Invoices from './invoices';
import CelebrationPrograms from './celebration_programs';
import CelebrationSchedules from './celebration_schedules';
import CelebrationEvents from './celebration_events';
import AssetImports from './asset_imports';
import UpcomingNewHires from './upcoming_new_hires';

export default {
  Presets,
  PresetBundles,
  Tasks,
  Users,
  UserDetails,
  Assets,
  CoffeeConfigurations,
  CoffeeMeetings,
  Teams,
  Share,
  Payment,
  Watercoolers,
  WatercoolerPrompts,
  WatercoolerPromptAdjustments,
  WatercoolerPacks,
  WatercoolerPacksPrompts,
  WatercoolerPacksWatercoolers,
  Reporting,
  ServiceAccounts,
  Channels,
  ChannelPrograms,
  Invoices,
  CelebrationPrograms,
  CelebrationSchedules,
  CelebrationEvents,
  AssetImports,
  UpcomingNewHires,
};
