import Network from './network';

export default {
  pairing_show(configId, startDate, endDate, args) {
    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append('start', startDate);
    if (endDate) queryParams.append('end', endDate);
    Network.get(`/reporting/${configId}/pairing_show?${queryParams.toString()}`, args);
  },
};
