import Network from './network';

export default {
  preloadImage(shareCode, args) {
    Network.post(
      `/share/${shareCode}/preload`,
      {},
      args,
    );
  },
};
