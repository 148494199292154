export default {
  updateCelebrationProgramMessage(state, payload) {
    state.celebrationProgram.messages[`${payload.grouped ? 'grouped_' : ''}${payload.celebrationType}`].current = payload.value;
  },

  updateCelebrationProgramImage(state, payload) {
    const image = state.celebrationProgram.images[payload.celebrationType].find(im => im.id === payload.imageId);
    image.selected = !image.selected;
  },
};
