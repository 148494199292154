export default {

  install: (app, options) => {
    app.mixin({
      methods: {
        toSentence(array, separator = ', ', lastSeparator = ' and ') {
          const arr = array.slice();
          const lastMember = arr.pop();
          let lastSep;
          if (array.length > 2) {
            lastSep = separator.trimRight() + lastSeparator;
          } else {
            lastSep = lastSeparator;
          }
          return arr.length ? arr.join(separator) + lastSep + lastMember : lastMember;
        },
      },
    });
  },

};
