import Network from './network';

export default {
  importDates(data, args) {
    args.contentType = 'multipart/form-data';
    args.resourceName = 'celebration_schedules_import';

    Network.post('/celebrations/import_dates_as_json', data, args);
  },

  index(celebrationProgramId, scheduleType, callbacks) {
    let queryParams = `celebration_program_id=${celebrationProgramId}`;
    if (scheduleType) {
      queryParams += `&schedule_type=${scheduleType}`;
    }
    Network.get(
      `/celebration_schedules/index_as_json?${queryParams}`,
      callbacks,
    );
  },

  update(scheduleId, userId, celebrationProgramId, scheduleParams, args) {
    Network.put(
      '/celebration_schedules/create_or_update_as_json',
      {
        user_id: userId,
        celebration_schedule_id: scheduleId,
        celebration_program_id: celebrationProgramId,
        celebration_schedule: scheduleParams,
      },
      args,
    );
  },

  sendAskForDatesDms(celebrationProgramId, userIds, args) {
    Network.post(
      '/celebration_schedules/send_ask_for_dates_dms',
      {
        celebration_program_id: celebrationProgramId,
        user_ids: userIds,
      },
      args,
    );
  },

  addOrRemoveProgram(scheduleId, celebrationProgramId, activate, args) {
    Network.put(
      `/celebration_schedules/${scheduleId}/update_included_programs_as_json`,
      {
        celebration_program_id: celebrationProgramId,
        activate,
      },
      args,
    );
  },
};
