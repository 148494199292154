export default {
  updateChannelProgramOwnership(state, { newOwner, newEditors, programType, permissionUsers }) {
    state[programType].creator = newOwner;
    // Update program.permission_users to update the labels to properly label you and the new owner.
    // This will wipe any changes made to permission users that hasn't been saved yet.
    // Saving permission users changes with AJAX whenever a user is added or removed will alleviate that.
    state[programType].permission_users = permissionUsers;
    state.owner = newOwner;
    state.users = newEditors;
  },

  updateEditablePrograms(state, data) {
    state.editablePrograms = data.editablePrograms;
  },

  updateChannelProgram(state, { program }) {
    // Update all mapState keys that might contain this channel program.
    // Keep in mind that this will overwrite, not merge, subhashes.
    const isSameRecord = (programA, programB) => programA.id === programB.id && programA.type === programB.type;
    ['coffeeConfiguration', 'watercooler', 'celebrationProgram', 'record'].forEach((k) => {
      if (state[k] && isSameRecord(state[k], program)) {
        state[k] = { ...state[k], ...program };
      }
    });
    if (state.successPrograms) {
      const progIdx = state.successPrograms.findIndex(p => isSameRecord(p, program));
      state.successPrograms[progIdx] = { ...state.successPrograms[progIdx], ...program };
    }
    if (state.channels) {
      state.channels.forEach((channel, channelIdx) => {
        const progIdx = channel.programs.findIndex(p => isSameRecord(p, program));
        if (progIdx !== -1) {
          const newProgram = { ...state.channels[channelIdx].programs[progIdx], ...program };
          state.channels[channelIdx].programs[progIdx] = newProgram;
        }
      });
    }
  },
};
