import API from 'lib/api/api';

export default {
  pollForDatesImport({ dispatch, commit, state }, importId) {
    const maxPollCount = 40; // 2 minutes
    let pollCount = 0;
    let polling = setInterval(() => {
      dispatch('getAssetImport', {
        importId,
        programId: state.celebrationProgram.id,
        programType: state.celebrationProgram.type,
      }).then((res) => {
        if (res) {
          dispatch('getCelebrationSchedules', {
            celebrationProgramId: state.celebrationProgram.id,
          });
        } else if (pollCount === maxPollCount) {
          dispatch('setErrorToast', 'Uh oh, something is taking a long time. Try reloading the page to see the new dates.');
        }
        // Stop polling if parsed results are returned, or too much time has passed
        if (res || pollCount === maxPollCount) {
          commit('uploads/updateFetchingData', false);
          clearInterval(polling);
          polling = null;
        } else {
          pollCount += 1;
        }
      });
    }, 3000); // Call every 3 seconds
  },
};
