import Network from './network';

export default {
  applyTemplate(callbacks, args = {}) {
    args.success_on = 'form';
    return Network.post('/channel_programs/apply_template_as_json', args, callbacks);
  },

  getEditable(args) {
    Network.get(
      '/channel_programs/editable_programs_as_json',
      args,
    );
  },
};
