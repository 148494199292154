import BuildingBlocks from './routes/building-blocks';
import CelebrationPrograms from './routes/celebration-programs';
import ChannelPrograms from './routes/channel-programs';
import CoffeeConfigurations from './routes/coffee-configurations';
import Onboarding from './routes/onboarding';
import OnboardingSite from './routes/onboarding-site';
import PresetCategories from './routes/preset-categories';
import PresetEvents from './routes/preset-events';
import Presets from './routes/presets';
import TagPresets from './routes/tag-presets';
import Tags from './routes/tags';
import Watercoolers from './routes/watercoolers';

export default {
  ...BuildingBlocks,
  ...CelebrationPrograms,
  ...ChannelPrograms,
  ...CoffeeConfigurations,
  ...Onboarding,
  ...OnboardingSite,
  ...PresetCategories,
  ...PresetEvents,
  ...Presets,
  ...TagPresets,
  ...Tags,
  ...Watercoolers,
};
