import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      tag: null,
      tags: [],
      selectedTags: [],
      flowType: null,
    };
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
  },

  actions: {
    track(_state, { eventName, properties }) {
      const fullEventName = `Enroll by Workspace Join: ${eventName}`;
      const fullProperties = properties || {};
      window.mixpanel.track(fullEventName, fullProperties);
      window.FS.event(fullEventName); // ignore fullProperties
      window.Intercom('trackEvent', fullEventName, fullProperties);
    },

    openSettingsModal({ commit, dispatch }, { tag, flowType }) {
      dispatch('track', { eventName: 'Opened Settings Modal' });
      commit('update', { key: 'tag', value: tag });
      commit('update', { key: 'flowType', value: flowType });
      Network.get('/onboarding/tags', {
        success: (response) => {
          commit('update', { key: 'tags', value: response.data });
          commit('updateModal', {
            visible: true,
            subcomponent: 'onboarding-tags-enrollment-slack-join-modals-settings',
            size: 'md of-y-v',
            data: { tag, tags: response, flowType },
          }, { root: true });
        },
      });
    },

    openWarningModal({ commit }, { autoEnrollTags }) {
      commit('updateModal', {
        visible: true,
        subcomponent: 'onboarding-tags-enrollment-slack-join-modals-warning',
        size: 'md',
        data: { autoEnrollTags },
      }, { root: true });
    },

    submitSettingsModal({ state, dispatch }, { tags }) {
      let autoEnrollTags;
      if (state?.tag) {
        autoEnrollTags = [...state.selectedTags, state.tag];
      } else {
        autoEnrollTags = tags;
      }
      if (autoEnrollTags.length === 0 && state.selectedTags.length > 0) {
        dispatch('openWarningModal', { autoEnrollTags });
      } else {
        dispatch('updateTags', { autoEnrollTags });
      }
    },

    submitWarningModal({ dispatch }, { autoEnrollTags }) {
      dispatch('updateTags', { autoEnrollTags });
    },

    updateTags({ commit, dispatch, state }, { autoEnrollTags }) {
      commit('update', { key: 'selectedTags', value: autoEnrollTags });
      const default_tag_ids = autoEnrollTags.map((tag) => tag.id);
      let enrollment_source;
      if (default_tag_ids.length === 0) {
        enrollment_source = 'none';
      } else {
        enrollment_source = 'auto_enroll';
      }
      const url = '/onboarding/tags/hris_category_mappings/bulk_update';

      Network.patch(url, { enrollment_source, default_tag_ids, mappings: '[]' }, {
        success: () => {
          dispatch('track', { eventName: 'Submitted Settings Modal Successfully' });
          const description = `All ${state.flowType.primaryRolePlural} will be enrolled in this journey automatically when they Slack.`;
          if (default_tag_ids.length > 0) {
            commit('updateModal', {
              visible: true,
              subcomponent: 'onboarding-tags-enrollment-slack-join-modals-success',
              size: 'md of-y-v',
              data: { description },
            }, { root: true });
          } else {
            commit('closeModal', null, { root: true });
          }
        },
        error: () => {
          dispatch('track', { eventName: 'Error Submitting Settings Modal' });
          dispatch('setErrorToast', 'Error saving settings. Please try again.', { root: true });
        },
      });
    },
  },
};
