import Network from 'lib/api/network';
import Routes from 'lib/routes';

export default {
  namespaced: true,

  state() {
    return {
      tag: null,
      uploadedLogo: null,
      sessions: [],
      hasChanges: false,
      published: false,
    };
  },

  getters: {
    involvedRoles(state) {
      return state.tag.involved_roles;
    },
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
    updateLogoAfterUpload(state, files) {
      state.uploadedLogo = files[0];
    },
  },

  actions: {
    saveChanges({ commit, dispatch, state }, { template }) {
      commit('update', { key: 'formSubmitted', value: true }, { root: true });
      const url = Routes.siteSave(state.tag);
      return new Promise((resolve, reject) => {
        Network.put(
          url,
          { template },
          {
            success: (data) => {
              dispatch('setSuccessToast', 'Settings saved!', { root: true });
              commit('update', { key: 'hasChanges', value: false });
              resolve(data);
            },
            error: (error) => {
              dispatch('setErrorToast', 'Something went wrong. Please try again.', { root: true });
              reject(error);
            },
            complete: () => {
              commit('update', { key: 'formSubmitted', value: false }, { root: true });
            },
          },
        );
      });
    },

    // TODO: Can I just get the tag from state?
    changePublished({ commit, dispatch, state }, { tag, published, flowType }) {
      commit('update', { key: 'formSubmitted', value: true }, { root: true });
      const url = Routes.siteSave(tag);
      return new Promise((resolve, reject) => {
        Network.put(
          url,
          { published },
          {
            success: (data) => {
              commit('update', { key: 'published', value: published });
              if (published) {
                dispatch('setSuccessToast', `${flowType.primaryRolePlural} can now access this Journey's Hub`, { root: true });
              } else {
                dispatch('setSuccessToast', `${flowType.primaryRolePlural} can no longer access this Journey's Hub`, { root: true });
              }
              resolve(data);
            },
            error: (error) => {
              dispatch('setErrorToast', 'Something went wrong. Please try again.', { root: true });
              reject(error);
            },
            complete: () => {
              commit('update', { key: 'formSubmitted', value: false }, { root: true });
            },
          },
        );
      });
    },

    openViewAsModal({ commit }, { tag }) {
      commit(
        'updateModal',
        {
          visible: true,
          dismissable: true,
          size: 'md of-v',
          subcomponent: 'onboarding-tags-sites-modals-session-select',
          data: { tag },
        },
        { root: true },
      );
    },

    fetchSessions({ commit }, { tag }) {
      return new Promise((resolve) => {
        Network.get(`/${tag.route_flow_type}/tags/${tag.id}/sessions`, {
          success: (response) => {
            commit('update', { key: 'sessions', value: response.sessions });
            resolve(response.sessions);
          },
        });
      });
    },

    openSite(_context, { sessionId }) {
      window.open(`/site/${sessionId}`, '_blank');
    },
  },
};
