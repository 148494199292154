const format = (el, binding) => {
  el.classList.add('display');
  el.innerHTML = Donut.Formatter.formatTextForSlack(binding.value, binding.modifiers);
};

export default {
  mounted(el, binding, vnode) {
    format(el, binding, vnode);
  },

  updated(el, binding, vnode) {
    if (binding.oldValue !== binding.value) {
      format(el, binding, vnode);
    }
  },
};
