import API from 'lib/api/api';
import Network from 'lib/api/network';

export default {
  getPremiumQuantitiesAndCosts({ commit, dispatch }, { useCache = false, coffeeConfigurationId = null, watercoolerId = null }) {
    commit('startLoading', 'getPremiumPricing');
    return new Promise((resolve, reject) => {
      API.Payment.getPremiumCostAndQuantity({
        useCache,
        coffeeConfigurationId,
        watercoolerId,
        success: (data) => {
          commit('updatePremiumQuantitiesAndCosts', data);
          resolve(true);
        },
        error: (error) => {
          dispatch('setErrorToast', 'Failed to calculate cost based on current usage. Try reloading?');
          resolve(false);
        },
        complete: () => { commit('endLoading', 'getPremiumPricing'); },
      });
    });
  },

  // TODO: Celebrations?
  getStandardQuantitiesAndCosts({ commit, dispatch }, { useCache = false, coffeeConfigurationId = null, watercoolerId = null }) {
    commit('startLoading', 'getStandardPricing');
    return new Promise((resolve, reject) => {
      API.Payment.getPaidCoffeeCostAndQuantity({
        useCache,
        coffeeConfigurationId,
        watercoolerId,
        success: (data) => {
          commit('updateStandardQuantitiesAndCosts', data);
          resolve(true);
        },
        error: (error) => {
          dispatch('setErrorToast', 'Failed to calculate cost based on current usage. Try reloading?');
          resolve(false);
        },
        complete: () => { commit('endLoading', 'getStandardPricing'); },
      });
    });
  },

  getInvoices({ commit, dispatch }, startingAfter) {
    commit('startLoading', 'getInvoices');
    return new Promise((resolve, reject) => {
      API.Invoices.get(startingAfter, {
        success: (data) => {
          commit('updateInvoices', data);
          resolve(true);
        },
        error: (error) => {
          dispatch('setErrorToast', 'Failed to retrieve invoices. Try again?');
          resolve(false);
        },
        complete: () => { commit('endLoading', 'getInvoices'); },
      });
    });
  },

  // Currently only used by the Profitwell Retain widget
  updatePlan({ commit, dispatch }, params) {
    commit('updateModal', {
      visible: true,
      subcomponent: 'donut-modal-takeover',
      data: {
        title: 'Updating your plan...',
        isLoading: true,
      },
    });
    return new Promise((resolve, reject) => {
      Network.post(
        '/sign_up/change_plan',
        params,
        {
          success: (data) => {
            commit('updateModal', {
              visible: true,
              subcomponent: 'donut-modal-takeover',
              data: {
                title: 'Success! Your plan has been updated.',
                category: 'success',
              },
            });
            // Since this is a test, reload the page. If retain is successful,
            // we may want to make all plan upgrades through json and respond
            // with the new account json values.
            setTimeout(() => {
              window.location.reload();
            }, 3000);
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast', error.responseJSON.error);
            commit('closeModal');
            resolve(false);
          },
        },
      );
    });
  },

  downgradePlan({ state, commit, dispatch }, params) {
    // When downgrading from premium or standard to free:
    //   In a local environment you will see the downgrade modal.
    //   On production, customers will see a profitwell "retain" modal.
    // (Profitwell does not provide dev API keys. If you need to edit this flow,
    // you'll have to do it very carefully with the production API key.)
    if (window.profitwell?.isLoaded) {
      const newPlan = 'free';
      commit('closeModal');
      dispatch('trackDowngradeAction', { name: 'Opened Downgrade Survey Modal', newPlan });
      window.profitwell('init_cancellation_flow').then((res) => {
        dispatch('trackDowngradeAction', { name: 'Completed Profitwell Retain Modal', newPlan, res });
        // TODO: should this logic include `res.salvageAttemptResult.hasErrors` or `res.status === 'error'?
        if (res.status === 'retained' || res.status === 'aborted') {
          // If they cancelled the retain flow or accepted a salvage attempt
          // -> do nothing
          // When `res.salvageAttemptUsed === 'plan_switch' && res.salvageAttemptResult.resolution === 'accepted'`
          // -> Profitwell does not currently return *which* plan the customer
          //    switched to, so the CX team manually makes these changes.
          // TODO: When Profitwell adds the plan the customer switched to to the
          //       JSON response, we should set `newPlan = res.todoMyNewPlan`.
          return;
        }
        dispatch('trackDowngradeAction', { name: 'Downgraded Plan', newPlan, res });
        dispatch('updatePlan', {
          plan: newPlan,
          source: 'profitwell_retain_flow',
          downgrade_survey: {
            plan_changing_reasons: {
              selected: [_.snakeCase(res.cancelReason)],
              other_info: res.additionalFeedback,
            },
          },
        });
      });
    } else {
      commit('updateModal', {
        visible: true,
        subcomponent: 'donut-plans-modals-downgrade',
        size: 'xl',
        data: state.modal.data,
      });
      dispatch('trackDowngradeAction', {
        name: 'Opened Downgrade Survey Modal',
        newPlan: state.modal.data.planDetails.plan,
      });
    }
  },

  trackDowngradeAction({ state, dispatch }, params) {
    const properties = {
      source: state.currentPlan === 'premium' && window.profitwell?.isLoaded ? 'profitwell_retain' : 'form_modal',
    };
    if (params.res) { properties.profitwellRetain = params.res; }
    dispatch('trackPlanChangeAction', {
      name: params.name,
      newPlan: params.newPlan,
      additionalProperties: {
        ...properties,
      },
    });
  },

  trackChangingToPaidPlanAction({ state, dispatch }, params) {
    dispatch('trackPlanChangeAction', {
      name: params.name,
      newPlan: params.newPlan,
      additionalProperties: {
        trialAllowed: params.trialAllowed,
        cardRequired: state.cardRequired,
      },
    });
  },

  trackPlanChangeAction({ state, rootGetters }, params) {
    const properties = {
      ...params.additionalProperties,
      currentPlan: state.currentPlan,
      newPlan: params.newPlan,
    };
    window.mixpanel.track(params.name, properties);
  },
};
