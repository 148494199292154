export default {
  closeModalAndDestroyDuplicateRecord({ commit }) {
    // When canceling a wizard modal...
    // Destroy the duplicate, close the modal
    commit('destroyDuplicateRecord');
    commit('closeModal');
  },

  closeModalAndMergeDuplicateRecord({ commit }) {
    // When saving a wizard modal...
    // Commit the changes on the duplicate to the base record, destroy the duplicate, close the modal
    commit('mergeDuplicateRecord');
    commit('destroyDuplicateRecord');
    commit('closeModal');
  },

  // Intros

  selectIntrosBuildingBlock({ commit, state }, idx) {
    // Apply all the intros_settings from a buildingBlock to the record
    // First, establish the selected buildingBlock
    commit('update', { key: 'selectedBuildingBlockIdx', value: idx });
    const buildingBlock = state.buildingBlocks[idx];

    // The channel information is not passed to the controller through the coffee_configuration name
    commit('updateRecord', { key: 'channel_name', value: buildingBlock.default_channel_name });
    commit('updateRecord', { key: 'channel_uid', value: null });

    // The intros_settings values of 'period', 'weekdays', and 'group_size'
    // can be directly mapped onto the record
    ['period', 'weekdays', 'group_size'].forEach((setting) => {
      commit('updateRecord', { key: setting, value: buildingBlock.intros_settings[setting] });
    });

    // Some buildingBlocks have specific category pairing defaults to apply,
    // the rest just need to clear any existing categories/groups
    let categoryName = null;
    let categoryOptions = [];
    if (buildingBlock.intros_settings.cross_group_pairing || buildingBlock.intros_settings.within_group_pairing) {
      categoryName = buildingBlock.intros_settings.category_name;
      categoryOptions = buildingBlock.intros_settings.category_options.map(option => ({ name: option }));
    }
    commit('updateRecord', { key: 'category_name', value: categoryName });
    commit('updateRecord', { key: 'category_options', value: categoryOptions });

    // None of the buildingBlocks have lottery defaults, but at least one lets you
    // set your own users. These need to be cleared when moving between buildingBlocks
    commit('updateRecord', { key: 'lottery_user', value: null });
    commit('updateRecord', { key: 'second_lottery_user', value: null });

    // Finally, update the pairing type
    let pairingType = 'standard';
    if (buildingBlock.intros_settings.is_lottery) { pairingType = 'lottery'; }
    if (buildingBlock.intros_settings.cross_group_pairing) { pairingType = 'cross_group_pairing'; }
    commit('updateRecord', { key: 'pairing_type', value: pairingType });
  },

  // Add members

  goToAllChannels() {
    window.location.href = '/all_channels';
  },
};
