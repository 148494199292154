import API from 'lib/api/api';

export default {
  createWatercoolerPromptAdjustment({ dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      API.WatercoolerPromptAdjustments.create(
        state.watercooler.id,
        params,
        {
          success: (data) => {
            dispatch('updateTopicsQueue', data);
            dispatch('setSuccessToast');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },

  destroyWatercoolerPromptAdjustment({ dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      API.WatercoolerPromptAdjustments.destroy(
        state.watercooler.id,
        params,
        {
          success: (data) => {
            dispatch('updateTopicsQueue', data);
            dispatch('setSuccessToast');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },
};
