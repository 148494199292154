import Network from 'lib/api/network';

export default {
  namespaced: true,

  actions: {
    sendCsv({ dispatch }, { endpoint, params }) {
      Network.post(endpoint, params, {
        success: () => {
          dispatch('setSuccessToast', {
            text: 'Your report is being generated. When ready, it will be sent to you in Slack in a direct message from Donut.',
            duration: 10000,
          }, { root: true });
        },
        error: () => {
          dispatch('setErrorToast', 'There was an unexpected error processing your request. Try again?', { root: true });
        },
      });
    },
  },
};
