import Network from 'lib/api/network';
import _ from 'lodash';
import * as moment from 'moment';

export default {
  namespaced: true,

  state() {
    return {
      tag: null,
      flowType: null,
      survey: null,
    };
  },

  getters: {
    isNew(state) {
      if (state.survey) return !state.survey.id;
    },

    roleSurveys(state) {
      if (state.survey) return state.survey.role_enrollment_surveys;
    },
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },

    updateSurvey(state, { key, value }) {
      state.survey[key] = value;
    },

    // Role survey question updates. Since we only display one set of question inputs,
    // each update below is done to both role_enrollment_surveys to keep them in sync.

    addBlankQuestion(state, type) {
      state.survey.role_enrollment_surveys.forEach(rs => {
        const collectFeedback = type !== 'text';
        const blankQuestion = {
          input_type: type,
          query: '',
          collect_feedback: collectFeedback,
          optional: true,
        };
        if (type === 'radio' || type === 'checkbox') {
          blankQuestion.options = [{ prompt: '' }, { prompt: '' }];
        }
        rs.questions.push(blankQuestion);
      });
    },
    addQuestions(state, questions) {
      state.survey.role_enrollment_surveys.forEach(rs => {
        rs.questions = questions.concat(rs.questions);
      });
    },
    updateQuestion(state, { idx, key, value }) {
      state.survey.role_enrollment_surveys.forEach(rs => {
        rs.questions[idx][key] = value;
      });
    },
    removeQuestion(state, { idx }) {
      state.survey.role_enrollment_surveys.forEach(rs => {
        rs.questions.splice(idx, 1);
      });
    },
    moveQuestion(state, { idx, direction }) {
      // Direction is either -1 (up) or 1 (down).
      const newIdx = idx + direction;
      state.survey.role_enrollment_surveys.forEach(rs => {
        const { questions } = rs;
        // Swap two adjacent questions.
        const question = questions[idx];
        questions[idx] = questions[newIdx];
        questions[newIdx] = question;
        rs.questions = questions;
      });
    },

    addBlankOption(state, { idx }) {
      state.survey.role_enrollment_surveys.forEach(rs => {
        rs.questions[idx].options.push({ text: '' });
      });
    },
    updateOption(state, { idx, oIdx, key, value }) {
      state.survey.role_enrollment_surveys.forEach(rs => {
        rs.questions[idx].options[oIdx][key] = value;
      });
    },
    removeOption(state, { idx, oIdx }) {
      state.survey.role_enrollment_surveys.forEach(rs => {
        rs.questions[idx].options.splice(oIdx, 1);
      });
    },

    updateRoleSurveyCount(state, useNonPrimarySurvey) {
      // This happens in the Mentor/Mentee toggle.
      // If we toggle it on, we want to add a new role_enrollment_survey that has the same questions as the former but no id and no role
      // If we toggle it off, we want to reset role_enrollment_surveys to just be an array containing the first, primary one
      // TODO: We might want to consider, instead of eliminating the second one entirely, storing it elsewhere just in case.
      // ... I can imagine a world in which a user untoggles, everything disappears, they retoggle,
      // ... and their previously filled out section is now just an empty, blank slate.
      const currentCount = state.survey.role_enrollment_surveys.length;
      if (useNonPrimarySurvey && currentCount === 1) {
        const questionsDupe = [];
        state.survey.role_enrollment_surveys[0].questions.forEach((question) => {
          const questionDupe = _.cloneDeep(question);
          questionDupe.id = null;
          questionsDupe.push(questionDupe);
        });
        state.survey.role_enrollment_surveys.push({
          id: null,
          role: null,
          questions: questionsDupe,
        });
      } else if (!useNonPrimarySurvey && currentCount === 2) {
        state.survey.role_enrollment_surveys = [state.survey.role_enrollment_surveys[0]];
      }
    },
    updateRoleSurvey(state, { idx, key, value }) {
      const roleSurvey = state.survey.role_enrollment_surveys[idx];
      if (roleSurvey) roleSurvey[key] = value;
    },
  },

  actions: {
    openInitialModal({ commit, dispatch }, { tag, flowType, source, startDate }) {
      let date;
      if (!startDate) {
        date = moment().startOf('isoWeek').add(1, 'week').format('MMM D, YYYY'); // Next monday
      } else {
        date = startDate;
      }
      if (tag) {
        commit('update', { key: 'flowType', value: flowType });
        dispatch('openSlackShareModal', {
          routeResource: flowType.routeResource,
          tag,
          startDate: date,
          source,
        });
      } else {
        dispatch('openTagSelectModal', { flowType, startDate: date });
      }
    },

    openTagSelectModal({ commit }, { flowType, startDate }) {
      commit('update', { key: 'flowType', value: flowType });
      commit('updateModal', {
        visible: true,
        subcomponent: 'onboarding-tags-enrollment-surveys-modals-tag-select',
        size: 'lg of-v',
        data: {
          startDate,
        },
      }, { root: true });
    },

    navigateToEnrollmentSurvey({ commit, state }, { tag }) {
      commit('update', { key: 'tag', value: tag });
      window.location = `/${state.flowType.routeResource}/tags/${tag.id}/enrollment_surveys/new`;
    },

    sendInviteMessage({ commit, dispatch }, {
      channelIds, userIds, routeResource, tag, startDate, reloadOnSuccess, surveyData,
    }) {
      return new Promise((resolve, reject) => {
        commit('update', { key: 'formSubmitted', value: true }, { root: true });
        const surveyId = tag.enrollment_survey_id;
        const url = `/${routeResource}/tags/${tag.id}/enrollment_surveys/${surveyId}/share_to_slack_as_json`;
        Network.post(
          url,
          { channel_ids: channelIds, user_ids: userIds, start_date: startDate, enrollment_survey: surveyData },
          {
            success: () => {
              commit('updateModal', {
                visible: true,
                subcomponent: 'onboarding-tags-enrollment-surveys-modals-slack-share-success',
                data: { tagName: tag.name, reloadOnSuccess },
              }, { root: true });
            },
            error: () => {
              dispatch('setErrorToast', null, { root: true });
            },
            complete: () => {
              commit('update', { key: 'formSubmitted', value: false }, { root: true });
            },
          },
        );
      });
    },

    openSlackShareModal({ commit }, {
     routeResource, tag, startDate, source, reloadOnSuccess,
    }) {
      commit('updateModal', {
        visible: true,
        subcomponent: 'onboarding-tags-enrollment-surveys-modals-slack-share',
        size: 'xxl',
        data: { routeResource, tag, startDate, reloadOnSuccess },
      }, { root: true });
      window.mixpanel.track('Enrollment Surveys: Slack Share Modal Opened', {
        source,
        tag: tag.id,
      });
    },

    fetchSurvey({ commit, dispatch }, { tag, routeResource }) {
      const url = `/${routeResource}/tags/${tag.id}/enrollment_surveys/${tag.enrollment_survey_id}`;
      Network.get(
        url,
        {
          success: (response) => {
            if (response.survey) {
              commit('update', { key: 'survey', value: response.survey });
            }
          },
          error: () => {
            dispatch('setErrorToast', null, { root: true });
          },
        },
      );
    },
  },
};
