import Network from './network';

export default {
  index(celebrationProgramId, callbacks) {
    Network.get(
      `/celebrations/${celebrationProgramId}/events/index_as_json`,
      callbacks,
    );
  },
};
