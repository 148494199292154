import Network from 'lib/api/network';
import _ from 'lodash';

export default {
  namespaced: true,

  state() {
    return {
      showWizard: false,
      bundles: [],
      flowTypeMetadata: null,
      tags: [],
      selectedFlowTypeName: null,
      selectedBundleId: null,
    };
  },

  getters: {
    selectedBundle(state) {
      return state.bundles.find(b => b.id === state.selectedBundleId);
    },

    selectedPresets(_state, getters) {
      if (getters.selectedBundle) {
        return getters.selectedBundle.presets.filter(preset => preset.active);
      }
      return [];
    },

    selectedPresetsRequirements(_state, getters) {
      return _.flatMap(getters.selectedPresets.map((preset) => _.flatMap(preset.events.map(event => event.requirements))));
    },

    wizardModalArgs: (_state) => (idx) => {
      const size = idx === 0 ? null : 'xl';
      return {
        visible: true,
        subcomponent: 'preset-bundles-modals-wizard',
        dismissable: false,
        size,
        data: { idx },
      };
    },
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },

    togglePreset(_state, preset) {
      preset.active = !preset.active;
    },

    updateBundle(_state, { bundle, key, value }) {
      bundle[key] = value;
    },
  },

  actions: {
    initializeBundles({ state, commit }, { bundles }) {
      commit('update', { key: 'bundles', value: bundles });
      // Bundles are coming in from BE with presets that have no concept of "active".
      // Manually set this to be a bool of "true" on every preset in every bundle.
      state.bundles.forEach((bundle) => {
        bundle.presets.forEach((preset) => {
          commit('togglePreset', preset);
        });
      });
    },

    getTags({ commit }) {
      return new Promise((resolve, _reject) => {
        Network.get('/onboarding/tags', {
          success: (data) => {
            commit('update', { key: 'tags', value: data });
          },
          error: (error) => {
            resolve(error);
          },
        });
      });
    },

    selectBundle({ commit, getters, dispatch }, { bundleId }) {
      // If there's no bundleId arg, we're deselecting an existing one. First, reset all of it's presets to 'active'
      if (!bundleId) {
        getters.selectedBundle.presets.forEach((preset) => {
          if (!preset.active) dispatch('togglePreset', { preset });
        });
      }
      // Next update the selectedBundleId to reflect the new selection or null if deselecting
      commit('update', { key: 'selectedBundleId', value: bundleId });
      // Push the ?id=:id param to the url if selecting one for show, else remove it if going to the gallery
      // Companion functionality to mounted hooks in gallery.vue and show.vue
      const url = new URL(window.location);
      if (bundleId) {
        url.searchParams.set('id', bundleId);
      } else {
        url.searchParams.delete('id');
      }
      window.history.pushState({}, '', url);
    },

    togglePreset({ commit, dispatch, getters }, { preset }) {
      commit('togglePreset', preset);
      dispatch('updateBundleSpecs');
      dispatch('track', {
        event: 'Toggled Preset',
        properties: {
          bundleId: getters.selectedBundle.id,
          bundleName: getters.selectedBundle.name,
          presetId: preset.id,
          presetName: preset.name,
          isOn: preset.active,
        },
      });
    },

    updateBundleSpecs({ getters, commit }) {
      // When toggling a preset, we need to adjust the bundles total specs on involved roles, full calendar span, and number of events
      const bundle = getters.selectedBundle;
      const activePresets = bundle.presets.filter(preset => preset.active);
      const roles = [];
      let earliestDay;
      let latestDay;
      let numEvents = 0;
      activePresets.forEach((preset) => {
        preset.involved_roles.forEach((role) => {
          if (!roles.some(r => r.id === role.id)) roles.push(role);
        });
        if (preset.earliest_day < earliestDay || !earliestDay) earliestDay = preset.earliest_day;
        if (preset.latest_day > latestDay || !latestDay) latestDay = preset.latest_day;
        numEvents += preset.num_events;
      });
      commit('updateBundle', { bundle, key: 'involved_roles', value: roles });
      commit('updateBundle', { bundle, key: 'earliest_day', value: earliestDay });
      commit('updateBundle', { bundle, key: 'latest_day', value: latestDay });
      commit('updateBundle', { bundle, key: 'num_events', value: numEvents });
    },

    track(_store, { event, properties }) {
      const fullEvent = `Preset Bundles: ${event}`;
      window.mixpanel.track(fullEvent, properties);
    },
  },
};
