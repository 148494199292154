import Network from './network';

export default {
  create(watercoolerId, params, callbacks) {
    Network.post(
      '/watercooler_packs/create_as_json',
      { watercooler_id: watercoolerId, watercooler_pack: params },
      callbacks,
    );
  },

  update(watercoolerId, params, callbacks) {
    Network.put(
      `/watercooler_packs/${params.id}/update_as_json`,
      { watercooler_id: watercoolerId, watercooler_pack: params },
      callbacks,
    );
  },

  suggestPrompts(packId, callbacks) {
    Network.get(`/watercooler_packs/${packId}/suggest_prompts_as_json`, callbacks);
  },
};
