import API from 'lib/api/api';

export default {
  getCelebrationSchedules({ commit }, { celebrationProgramId, scheduleType, forHrisSyncData }) {
    const loadingEvent = scheduleType === 'custom' ? 'getCustomSchedules' : 'getCelebrationSchedules';
    commit('startLoading', loadingEvent);
    return new Promise((resolve, reject) => {
      API.CelebrationSchedules.index(
        celebrationProgramId,
        scheduleType,
        {
          success: (data) => {
            if (forHrisSyncData) {
              resolve(data);
            } else if (scheduleType === 'custom') {
              commit('updateCustomSchedules', data);
            } else {
              commit('updateCelebrationSchedules', data);
            }
            resolve(true);
          },
          error: (error) => {
            resolve(false);
          },
          complete: () => { commit('endLoading', loadingEvent); },
        },
      );
    });
  },

  sendAskForCelebrationDatesDms({ state, dispatch }, { userIds }) {
    return new Promise((resolve, reject) => {
      API.CelebrationSchedules.sendAskForDatesDms(
        state.celebrationProgram.id,
        userIds,
        {
          success: (data) => {
            dispatch('setSuccessToast', 'Messages sent!');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },

  updateCelebrationSchedule({ commit, dispatch }, { scheduleId, userId, celebrationProgramId, scheduleParams }) {
    return new Promise((resolve, reject) => {
      API.CelebrationSchedules.update(
        scheduleId,
        userId,
        celebrationProgramId,
        scheduleParams,
        {
          success: (data) => {
            if (data.customSchedules) {
              commit('updateCustomSchedules', data.customSchedules);
            } else {
              commit('updateCelebrationSchedule', data);
            }
            if (data.showUpgradeModal) {
              commit('updateModal', {
                visible: true,
                subcomponent: 'celebration-programs-modals-upgrade',
                size: 'xl',
                dismissable: false,
              });
            }
            dispatch('setSuccessToast', `Celebration ${data.action}!`);
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },

  updateCelebrationProgramInclusion({ commit, dispatch }, { scheduleId, celebrationProgramId, activate, trialAllowed }) {
    return new Promise((resolve, reject) => {
      API.CelebrationSchedules.addOrRemoveProgram(
        scheduleId,
        celebrationProgramId,
        activate,
        {
          success: (data) => {
            commit('updateCustomSchedules', data.customSchedules);
            dispatch('setSuccessToast', `Celebration ${data.action} for program`);
            resolve(true);
          },
          error: (error) => {
            if (error.status === 402) {
              dispatch('openChannelProgramsPlanUpgradeModal', {
                data: {
                  trialAllowed,
                  defaultPremiumPlan: true,
                  featureTitleText: 'celebrate unlimited custom dates',
                  source: 'celebrate-toggle-over-limit',
                  featureInfo: {
                    programKey: 'celebrations',
                    feature: 'celebrations-unlimited-celebrations',
                  },
                },
              });
              commit('updateCustomSchedules', error.responseJSON.customSchedules);
            } else {
              dispatch('setErrorToast');
            }
            resolve(false);
          },
        },
      );
    });
  },

  openBeforeAskForDatesModal({ commit, state, dispatch }, { modalData }) {
    if (!state.hrisConnect?.identity || ['relink_needed', 'deactivated'].includes(state.hrisConnect?.identity?.state)) {
      commit('updateModal', { visible: true,
        subcomponent: 'celebration-programs-modals-hris-before-ask-for-dates',
        data: {
          ...modalData,
        } });
    } else {
      dispatch('openAskForDatesModal', {
        modalData,
      });
    }
  },

  openAskForDatesModal({ commit, getters }, { modalData }) {
    commit('updateModal', {
      visible: true,
      subcomponent: 'celebration-programs-modals-ask-for-dates',
      data: {
        ...modalData,
        userList: _.cloneDeep(getters.userDatesDetails.usersMissingDates),
      },
    });
  },

  openInviteUsersModal({ commit }, { modalData }) {
    commit('updateModal', {
      visible: true,
      subcomponent: 'celebration-programs-modals-invite-users',
      data: modalData,
    });
  },

};
