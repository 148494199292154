import Network from './network';

export default {
  create(watercoolerId, packId, promptId, callbacks) {
    Network.post(
      `/watercooler_packs/${packId}/prompts/create_as_json`,
      { watercooler_id: watercoolerId, prompt_id: promptId },
      callbacks,
    );
  },

  destroy(watercoolerId, packId, promptId, callbacks) {
    Network.delete(
      `/watercooler_packs/${packId}/prompts/destroy_as_json`,
      { watercooler_id: watercoolerId, prompt_id: promptId },
      callbacks,
    );
  },

  reorder(watercoolerId, packId, promptId, direction, callbacks) {
    Network.put(
      `/watercooler_packs/${packId}/prompts/reorder_as_json`,
      { watercooler_id: watercoolerId, prompt_id: promptId, direction },
      callbacks,
    );
  },
};
