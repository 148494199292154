import Network from './network';

export default {
  updateAdmins(serviceAccount, userId, actionType, args) {
    Network.post(
      '/payment/update_admins_as_json',
      { service_account: serviceAccount, user_id: userId, action_type: actionType },
      args,
    );
  },

  updateOwnNewConfigs(serviceAccountId, ownNewConfigs, args) {
    Network.post(
      '/payment/update_own_new_configs',
      { service_account: serviceAccountId, own_new_configs: ownNewConfigs },
      args,
    );
  },

  updateAccountOwner(newOwnerId, args) {
    Network.put(
      '/billing/update_ownership',
      { new_owner_id: newOwnerId },
      args,
    );
  },
};
