import Network from '../../lib/api/network';

export default {
  namespaced: true,

  getters: {
    sendableNoun() {
      return (program) => {
        // eslint-disable-next-line default-case
        switch (program.type) {
          case 'CoffeeConfiguration':
            return 'round of intros';
          case 'Watercooler':
            return 'topic';
        }
      };
    },

    sentText(state, getters) {
      return (program, showCheckmark) => {
        let text = `${showCheckmark ? ':white_check_mark: ' : ''}A ${getters.sendableNoun(program)} was just sent!`;
        if (program.send_now_data.slack_url) {
          text += ` Check it out in <${program.send_now_data.slack_url}|Slack>.`;
        }
        return Donut.Formatter.formatTextForSlack(text, { linkify: true });
      };
    },
  },

  actions: {
    updateSuccessModal({ commit, getters }, { programs }) {
      // We are, for now, not using channel-programs-modals-save-success-send-now-watercooler when
      // the only success program is a Watercooler that allows send now.
      commit('updateModal', {
        visible: true,
        dismissable: false,
        subcomponent: 'channel-programs-modals-save-success-wrapper',
        data: { programs },
        size: programs.length > 2 ? 'lg' : null,
      }, { root: true });
    },

    getSendNowComplete({ commit, dispatch, rootState, getters }, { program, source }) {
      return new Promise((resolve, reject) => {
        Network.get(`/${program.controller_path}/${program.id}/send_now_complete_as_json`, {
          success: (data) => {
            if (data.done) {
              const programForUpdate = data.program;
              programForUpdate.send_now_data.isLoading = false;
              commit('updateChannelProgram', { program: programForUpdate }, { root: true });
              if (rootState.persistedRecord) {
                let { persistedRecord } = rootState;
                // To (sometimes) update fixed meeting time fields on the object.
                const shouldUpdateMeetingTiming = persistedRecord.type === 'CoffeeConfiguration';
                if (shouldUpdateMeetingTiming) {
                  commit('updateMeetingTiming', { key: 'next_occurrence_on', value: programForUpdate.next_occurrence_on }, { root: true });
                  persistedRecord = rootState.coffeeConfiguration;
                }
                const programForUpdate2 = { ...persistedRecord, ...programForUpdate };
                commit('update', { key: 'persistedRecord', value: programForUpdate2 }, { root: true });
                // `updateMeetingTiming` only sets its fields on `coffeeConfiguration`, so we need to do this commit
                // again to set those fields on all of the other rootState keys too.
                // They're not actually used by any keys but `coffeeConfiguration` right now anyway, but let's not
                // leave things open to bugs later!
                if (shouldUpdateMeetingTiming) {
                  commit('updateChannelProgram', { program: programForUpdate2 }, { root: true });
                }
              }
              dispatch('stopLoading', programForUpdate);
              if (source === 'send_now via program_card') {
                const toast = { text: getters.sentText(programForUpdate), type: 'success', html_safe: true, duration: 10000 };
                dispatch('setToast', toast, { root: true });
                commit('closeModal', null, { root: true });
              } else if (rootState.modal.subcomponent !== 'channel-programs-modals-save-success-wrapper') {
                dispatch('updateSuccessModal', { programs: rootState.modal.cancelArgs.data.programs });
              }

              resolve(true);
            } else {
              resolve(false);
            }
          },
          error: (error) => {
            resolve(false);
          },
        });
      });
    },

    pollForSendNowComplete({ dispatch, commit, state }, { program, source }) {
      const maxPollCount = 40; // 2 minutes
      let pollCount = 0;
      const runLoopIteration = () => {
        dispatch('getSendNowComplete', { program, source }).then((res) => {
          // If no success yet,
          if (!res) {
            // poll again in 3 seconds unless too much time has passed.
            if (pollCount < maxPollCount) {
              pollCount += 1;
              setTimeout(runLoopIteration, 3000);
            } else {
              dispatch('setErrorToast', 'Uh oh, something is taking a long time. You can go to Slack to see if your event has sent.');
              dispatch('stopLoading', program);
            }
          }
        });
      };

      // Run immediately
      runLoopIteration();
    },

    stopLoading({ commit }, program) {
      commit('update', { key: 'formSubmitted', value: false }, { root: true });
      commit('updateChannelProgram', { program }, { root: true });
    },

    sendNow({ state, rootState, commit, dispatch }, args) {
      const { program } = args;
      const isIntrosProgramWithoutPeople = program.type === 'CoffeeConfiguration' && program.num_participants < 2;
      // Don't actually send now! Try to get more participants first.
      if (isIntrosProgramWithoutPeople) {
        commit('updateModal', {
          visible: true,
          subcomponent: 'channel-programs-modals-invite-users',
          data: {
            source: args.source,
            program,
          },
          cancelMutation: 'updateModal',
          cancelArgs: rootState.modal,
        }, { root: true });
        return;
      }

      window.mixpanel.track('Post-Create Send Now', {
        programType: program.type,
        nextOccurrenceDaysFromNow: program.next_occurrence_on_days_from_now,
        source: args.source,
      });

      program.send_now_data.isLoading = true;
      commit('update', { key: 'formSubmitted', value: true }, { root: true });
      commit('updateChannelProgram', { program }, { root: true });

      let path;
      switch (program.type) {
        case 'CoffeeConfiguration':
          path = `/coffee/${program.id}/trigger_arrangement_as_json`;
          break;
        case 'Watercooler':
          path = `/watercooler/${program.id}/send_prompt_as_json`;
          break;
        default:
          path = '';
      }

      Network.post(path, {}, {
        success: () => {
          dispatch('pollForSendNowComplete', { program, source: args.source });
        },
        error: (error) => {
          dispatch('setErrorToast', null, { root: true });
          dispatch('stopLoading', program);
        },
      });
    },

    trackSaveSuccessModalOpen({ rootState }) {
      const { programs } = rootState.modal.data;
      const config = programs.find(p => p.type === 'CoffeeConfiguration');
      window.mixpanel.track('Program creation success modal', {
        programType: programs.map(p => p.type).sort().join(','),
        // eslint-disable-next-line camelcase
        sendNowIntros: config?.send_now_data?.allow_send_now || false,
        introsNeedsPeople: config && config.num_participants < 2,
        // eslint-disable-next-line camelcase
        sendNowWatercooler: programs.find(p => p.type === 'Watercooler')?.send_now_data?.allow_send_now || false,
        path: window.location.pathname.replace(/\/\d+\//, '/*/'),
        updated: rootState.updated,
      });
    },

    fetchSendNowProgramsData({ commit }, { programs }) {
      Network.put('/channel_programs/send_now_data_as_json', { programs }, {
        success: (data) => {
          data.programs.forEach((program) => {
            commit('updateChannelProgram', { program }, { root: true });
          });
        },
      });
    },
  },
};
