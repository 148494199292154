export default {
  updatePremiumQuantitiesAndCosts(state, val) {
    state.quantitiesAndCosts.premium = val;
  },
  updateStandardQuantitiesAndCosts(state, val) {
    state.quantitiesAndCosts.paid_coffee = val;
  },
  updateInvoices(state, data) {
    state.invoices = state.invoices.concat(data.invoices);
    state.nextCursor = data.nextCursor;
  },
};
