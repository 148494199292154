import API from 'lib/api/api';

export default {
  namespaced: true,

  state() {
    return {
      newHires: null,
      newHiresCount: null,
      isLoading: false,
    };
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
  },

  actions: {
    getNewHires({ dispatch, commit }) {
      commit('update', { key: 'isLoading', value: true });
      API.UpcomingNewHires.getAll({
        success: (data) => {
          commit('update', { key: 'newHires', value: data?.employees });
          commit('update', { key: 'newHiresCount', value: data?.total_count });
        },
        error: (_error) => {
          dispatch('setErrorToast', null, { root: true });
        },
        complete: () => {
          commit('update', { key: 'isLoading', value: false });
        },
      });
    },
  },
};
