const backgroundColor = (program) => {
  switch (program.type) {
    case 'CoffeeConfiguration':
      return program.is_active ? 'bg-yellow-light' : 'bg-yellow-lighter';
    case 'Watercooler':
      return program.is_active ? 'bg-lighter-purple' : 'bg-form-light';
    case 'CelebrationProgram':
      return program.is_active ? 'bg-green-light' : 'bg-green-lighter';
    default:
      return '';
  }
};

const highlightClasses = (program) => {
  switch (program.type) {
    case 'CoffeeConfiguration':
      return ['bg-peach'];
    case 'Watercooler':
      return ['bg-blue-full', 'white'];
    case 'CelebrationProgram':
      return ['bg-dark-green', 'white'];
    default:
      return [];
  }
};

const addColorClass = (el, binding, vnode) => {
  // eslint-disable-next-line default-case
  switch (binding.arg) {
    case 'success':
      el.classList.add('bg-green-pale');
      return;
    case 'background':
      el.classList.add(backgroundColor(binding.value));
      return;
    case 'highlight':
      el.classList.add(...highlightClasses(binding.value));
  }
};

export default {
  mounted(el, binding, vnode) {
    addColorClass(el, binding, vnode);
  },

  updated(el, binding, vnode) {
    if (binding.oldValue !== binding.value) {
      addColorClass(el, binding, vnode);
    }
  },
};
