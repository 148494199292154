import Network from './network';

export default {
  create(watercoolerId, promptParams, callbacks) {
    Network.post(
      '/watercooler_prompts/create_as_json',
      { watercooler_id: watercoolerId, watercooler_prompt: promptParams },
      callbacks,
    );
  },

  bulkCreate(watercoolerId, promptParams, callbacks, packId = null) {
    Network.post(
      '/watercooler_prompts/bulk_create_as_json',
      { watercooler_id: watercoolerId, pack_id: packId, watercooler_prompts: promptParams },
      callbacks,
    );
  },

  update(watercoolerId, promptId, promptParams, callbacks) {
    Network.put(
      `/watercooler_prompts/${promptId}/update_as_json`,
      { watercooler_id: watercoolerId, watercooler_prompt: promptParams },
      callbacks,
    );
  },

  destroy(watercoolerId, promptId, callbacks) {
    Network.delete(
      `/watercooler_prompts/${promptId}/destroy_as_json`,
      { watercooler_id: watercoolerId },
      callbacks,
    );
  },

  suggestPrompts(watercoolerId, callbacks) {
    Network.get(`/watercooler/${watercoolerId}/suggest_prompts_as_json`, callbacks);
  },
};
