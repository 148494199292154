import Network from './network';

export default {
  update(coffeeMeeting, args, callbacks) {
    Network.put(
      `/coffee_meetings/${coffeeMeeting.id}`,
      args,
      callbacks,
    );
  },
};
