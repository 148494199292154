export default {
  userDatesDetails(state, getters) {
    // For CelebrationProgram specific components, pull the specific program and its schedules out of state
    return getters.userDatesDetailsForProgram(state.celebrationProgram, state.celebrationSchedules);
  },

  userDatesDetailsForProgram: state => (program, celebrationSchedules) => {
    // Can be used for multiple programs and their celebrationSchedules at once
    // or just for the one specific program if called from userDatesDetails above
    const details = {
      stats: _.cloneDeep(program.celebrationTypesData),
      usersMissingDates: [],
    };
    delete details.stats.custom;
    for (const s in details.stats) {
      details.stats[s].count = 0;
      details.stats[s].privateCount = 0;
    }
    // NOTE: This is slow. When CelebrationSchedules are paginated this
    // info will need to be sent from the backend instead.
    for (const s of celebrationSchedules) {
      if (s.birthday) {
        if (s.birthday.private) {
          details.stats.birthday.privateCount += 1;
        } else if (s.birthday.date) {
          details.stats.birthday.count += 1;
        }
      }
      if (s.anniversary && s.anniversary.date) {
        details.stats.anniversary.count += 1;
      }
      if ((!s.anniversary?.date && program.anniversary) || (!s.birthday?.date && !s.birthday?.private && program.birthday)) {
        details.usersMissingDates.push(s.user);
      }
    }

    return details;
  },
};
