<script>
  import DonutSelexRemote from './remote.vue';

  export default {
    extends: DonutSelexRemote,

    props: {
      remoteUrl: {
        type: String,
        default: '/channels/search',
      },
      placeholder: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
      labelKey: {
        type: String,
        default: 'name',
      },
      valueKey: {
        type: String,
        default: 'uid',
      },
      includeShared: {
        type: Boolean,
        default: false,
      },
      includePrivate: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      defaultPlaceholder() {
        if (this.includePrivate) {
          return 'Search Slack channels...';
        }
        return 'Search public Slack channels...';
      },

      extraParams() {
        return {
          include_shared: Number(this.includeShared),
          include_private: Number(this.includePrivate),
        };
      },
    },
  };
</script>
