import API from 'lib/api/api';

export default {
  getUser({ commit, dispatch }, { verifyGoogleIdentity, verifyAnyCalIdentity, verifyZoomIdentity }) {
    return new Promise((resolve, reject) => {
      API.Users.getUser({
        includeCalIdentities: verifyGoogleIdentity || verifyAnyCalIdentity,
        includeZoomIdentity: verifyZoomIdentity,
        success: (data) => {
          commit('update', { key: 'currentUser', value: data });
          const message = {
            text: "Great! We've updated your status!",
            action: 'setSuccessToast',
          };
          // Verifying Zoom is mututally exclusive from verifying any cal identities.
          if (verifyZoomIdentity) {
            if (data.has_valid_zoom_identity) {
              message.text = "You've successfully linked your Zoom account!";
            } else {
              message.text = "It looks like your Zoom account hasn't been linked yet. Try linking your Zoom account again?";
              message.action = 'setErrorToast';
            }
          } else if (verifyGoogleIdentity || verifyAnyCalIdentity) {
            const authedGoogle = (verifyAnyCalIdentity || verifyGoogleIdentity) && data.has_valid_google_identity;
            const authedMicrosoft = verifyAnyCalIdentity && data.has_valid_microsoft_identity;
            if (authedGoogle || authedMicrosoft) {
              message.text = "You've successfully linked your calendar!";
            } else {
              message.text = "It looks like your calendar hasn't been linked yet. Try linking your calendar again?";
              message.action = 'setErrorToast';
            }
          }
          dispatch(message.action, message.text);
          resolve(true);
        },
        error: (error) => {
          dispatch('setErrorToast');
          resolve(false);
        },
      });
    });
  },

  closeAnnouncement({ commit, dispatch }, location) {
    const flag = `closed_${location}_announcement`;
    return new Promise((resolve, reject) => {
      API.UserDetails.recordProgress(flag, {
        success: (data) => {
          commit('recordClosedAnnouncement', data);
          resolve(true);
        },
        error: (error) => {
          dispatch('setErrorToast');
          resolve(false);
        },
      });
    });
  },
};
