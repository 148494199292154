import Network from './network';

export default {
  completeTaskAssignment(task, args) {
    Network.put(
      `/onboarding/task_assignments/${task.id}`,
      { state: 'completed', source: 'action_items' },
      args,
    );
  },
};
