export default {
  pairingOptions(state) {
    const needsUpgrade = !state.coffeeConfiguration.service_account.paidFeatureAccess;
    const isDisabled = state.coffeeConfiguration.pairing_type === 'topic_based';
    const options = {
      standard: {
        title: 'Standard',
        description: 'Anyone in the channel can be introduced to anyone in the channel',
        icon: 'nucleo/intro-standard',
        value: 'standard',
        requiresUpgrade: false,
        disabled: isDisabled,
      },
      topic: {
        title: 'Intro by interests',
        description: 'Intro people based on mutual interests and topics',
        icon: 'nucleo/intro-standard',
        value: 'topic_based',
        requiresUpgrade: false,
        disabled: isDisabled,
      },
      crossGroup: {
        title: 'Cross-group',
        description: 'Make introductions between specific groups, such as location or mentor/mentee',
        icon: 'nucleo/intro-cross-group',
        value: 'cross_group_pairing',
        requiresUpgrade: needsUpgrade,
        disabled: isDisabled,
      },
      withinGroup: {
        title: 'Within-group',
        description: 'Make introductions within specific groups, such as region',
        icon: 'nucleo/intro-within-group',
        value: 'within_group_pairing',
        requiresUpgrade: needsUpgrade,
        disabled: isDisabled,
      },
      lottery: {
        title: 'Lottery',
        description: 'One group from the channel will be matched up per round, such as for a lunch lottery',
        moreInfo: 'The longer someone goes without being selected for a group, the more likely they are to be selected.',
        icon: 'nucleo/event-ticket',
        value: 'lottery',
        requiresUpgrade: needsUpgrade,
        disabled: isDisabled,
      },
    };
    const selectedOption = Object.values(options).find(option => option.value === state.coffeeConfiguration.pairing_type);
    options.selected = selectedOption;
    return options;
  },
};
