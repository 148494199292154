import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      loading: false,
      coffeeBuildingBlocks: [],
      tags: [],
    };
  },

  mutations: {
    setTemplatesData(state, data) {
      state.coffeeBuildingBlocks = data.coffeeBuildingBlocks;
      state.tags = [
        { name: 'All', selected: true },
        ...data.tags.map(tag => ({ ...tag, selected: false })),
      ];
    },
  },

  actions: {
    openTemplatesModal({ state, rootState, commit, dispatch }, args) {
      if (!state.coffeeBuildingBlocks.length) {
        dispatch('fetchBuildingBlocks', args);
      } else {
        commit('updateModal', {
          visible: true,
          subcomponent: 'channel-programs-modals-templates',
          data: { channel: args?.channel, tagId: args?.tagId },
          size: 'xxl h-max-90vh',
          cancelMutation: 'updateModal',
          cancelArgs: rootState.modal,
          scrollToTopOnChange: true,
        }, { root: true });
        window.mixpanel.track('Opened templates modal', { location: args?.location, tagId: args?.tagId });
      }
    },

    fetchBuildingBlocks({ commit, state, dispatch, rootState }, args) {
      state.loading = true;
      return new Promise((resolve) => {
        Network.get(`/templates?hide_defaults=${!!args?.hideDefaults}`, {
          success: (data) => {
            commit('setTemplatesData', data);
            dispatch('openTemplatesModal', args);
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast', null, { root: true });
            resolve(false);
          },
          complete: () => {
            state.loading = false;
          },
        });
      });
    },
  },
};
