const type = record => record.type.charAt(0).toLowerCase() + record.type.slice(1);

export default {
  updateRecord(state, payload) {
    // Like updateCoffeeConfiguration or updateWatercooler, but for the base "record"
    // that exists in the form, outside of modals
    state.record[payload.key] = payload.value;
  },

  createDuplicateRecord(state) {
    // Duplicate the record and name it what the record type is. This allows the duplicate
    // to be manipulated via donut-form-items that look for `coffeeConfiguration`,
    // or `watercooler` records in state
    // Use JSON.parse(...) to create a deep dup of the object
    const duplicate = JSON.parse(JSON.stringify(state.record));
    state[type(state.record)] = duplicate;
  },

  destroyDuplicateRecord(state) {
    // Destroy this duplicate. The duplicates should only exist when a modal is open.
    state[type(state.record)] = null;
  },

  mergeDuplicateRecord(state) {
    // Take the duplicate which has been manipulated by donut-form-items and replace
    // the base record to simulate "saving" changes to it.
    const duplicateRecord = state[type(state.record)];
    if (duplicateRecord.calendar_scheduling_selection === 'no-scheduling') {
      duplicateRecord.disabled_gcal = true;
      duplicateRecord.disabled_outlook = true;
      duplicateRecord.autoschedule_partystarters = false;
    }
    state.record = Object.assign({}, duplicateRecord);
  },
};
