import API from 'lib/api/api';

export default {
  updateTeam({ state, commit, dispatch }, params) {
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      API.Teams.update(
        state.currentTeam,
        { pairing_welcome_message: params.prefix },
        {
          success: () => {
            dispatch('setSuccessToast', "Great! We've updated the welcome message new joiners will receive!");
            commit('update', {
              key: 'welcomingPrefix',
              value: params.prefix,
            });
            if (params.closeModal) commit('closeModal');
          },
          error: () => {
            dispatch('setErrorToast');
          },
          complete: () => {
            commit('update', { key: 'formSubmitted', value: false });
          },
        },
      );
    });
  },
};
