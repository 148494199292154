import API from 'lib/api/api';
import Network from 'lib/api/network';

export default {
  getConfigStats({ commit, dispatch, state }) {
    const loaderName = 'getConfigStats';
    commit('startLoading', loaderName);
    return new Promise((resolve, reject) => {
      API.Reporting.pairing_show(state.id, state.startDate, state.endDate, {
        success: (data) => {
          ['batches', 'stats', 'maxPairsInBatch'].forEach((field) => {
            commit('update', { key: field, value: data[field] });
          });
        },
        error: () => {
          dispatch('setErrorToast');
        },
        complete: () => { commit('endLoading', loaderName); },
      });
    });
  },
  getDateFilteredResults({ state }) {
    const url = new URL(window.location.href);
    const { pathname, searchParams } = url;
    const oldStartDate = searchParams.get('start_date') === 'null' ? null : searchParams.get('start_date');
    const newStartDate = state.startDate;
    const oldEndDate = searchParams.get('end_date') === 'null' ? null : searchParams.get('end_date');
    const newEndDate = state.endDate;
    if (oldStartDate !== newStartDate || oldEndDate !== newEndDate) {
      searchParams.set('start_date', newStartDate);
      searchParams.set('end_date', newEndDate);
      window.location.href = `${pathname}?${searchParams.toString()}`;
    }
  },
  getStrategicConnectionsPreview(
    { commit, dispatch, state },
    { recordId, tagId, batchId },
  ) {
    const loaderName = 'getStrategicConnectionsPreview';
    commit('startLoading', loaderName);

    const queryParams = new URLSearchParams();
    if (tagId) queryParams.append('tag_id', tagId);
    if (batchId) queryParams.append('batch_id', batchId);
    if (state.startDate) queryParams.append('start_date', state.startDate);
    if (state.endDate) queryParams.append('end_date', state.endDate);

    queryParams.append('id', recordId);
    const path = `/reporting/strategic_connections_preview?${queryParams.toString()}`;

    return new Promise((resolve) => {
      Network.get(path, {
        success: (data) => {
          resolve(data);
        },
        error: () => {
          resolve(null);
          dispatch('setErrorToast');
        },
        complete: () => { commit('endLoading', loaderName); },
      });
    });
  },
};
