import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      tag: null,
      tags: null,
      defaultHrisTags: null,
      roles: null,
      selectionSettings: {},
      flowType: null,
      flowTypeOptions: null,
      initDisableAutoEnrollInput: false,
      tagNameHasBeenUpdated: false,
    };
  },

  getters: {
    flowType(state) {
      const flowTypeKey = state.tag.flow_type === 'onboarding' ? 'onboarding' : 'other';
      return state.flowTypeOptions[flowTypeKey];
    },
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },

    updateTag(state, { key, value }) {
      state.tag[key] = value;
    },

    updateSelectionSettings(state, { setting }) {
      state.selectionSettings[setting.selectee_role.id] = setting;
    },

    addPermissionUser(state, user) {
      state.tag.permission_users.push(user);
    },

    removePermissionUser(state, user) {
      const idx = state.tag.permission_users.findIndex(u => u.id === user.id);
      if (idx > -1) {
        state.tag.permission_users.splice(idx, 1);
      }
    },
  },

  actions: {
    updatePermissionUsers({ commit }, { actionType, user }) {
      if (actionType === 'add') {
        commit('addPermissionUser', user);
      } else if (actionType === 'remove') {
        commit('removePermissionUser', user);
      }
    },

    selfEnrollValidation(_context, { tagId }) {
      return new Promise((resolve) => {
        Network.get(`${tagId}/self_enroll_validation`, {
          success: (response) => {
            resolve(response);
          },
        });
      });
    },

    selfEnroll({ commit, dispatch }, { tagId }) {
      return new Promise((resolve) => {
        Network.post(
          `${tagId}/self_enroll`,
          {},
          {
            success: () => {
              commit(
                'updateModal',
                {
                  visible: true,
                  size: 'lg',
                  dismissable: false,
                  subcomponent: 'onboarding-events-modals-self-enroll-success',
                },
                { root: true },
              );
              resolve(true);
            },
            error: () => {
              dispatch('setErrorToast', 'There was an unexpected error processing your request. Try again?', {
                root: true,
              });
              resolve(false);
            },
          },
        );
      });
    },

    trackEvent(_context, { eventName, properties }) {
      window.mixpanel.track(eventName, properties);
      window.FS.event(eventName);
      window.Intercom('trackEvent', eventName, properties);
    },

    selfTest({ commit, dispatch }, { tagId }) {
      return new Promise((resolve) => {
        Network.post(
          `${tagId}/self_test`,
          {},
          {
            success: () => {
              commit('closeModal', null, { root: true });
              dispatch('onboardingTags/trackEvent', {
                eventName: 'Closed Self Enroll Modal',
                properties: {
                  tagId,
                  source: 'self test',
                },
              });
              dispatch('setSuccessToast', 'Donut will send you the messages shortly.', { root: true });
              resolve(true);
            },
            error: () => {
              dispatch('setErrorToast', 'There was an unexpected error processing your request. Try again?', {
                root: true,
              });
              resolve(false);
            },
          },
        );
      });
    },

    fetchTags({ commit }, { scope }) {
      let routeParam;
      if (scope === 'onboarding') {
        routeParam = 'onboarding';
      } else if (scope === 'not_onboarding') {
        routeParam = 'participants';
      }
      Network.get(`/${routeParam}/tags`, {
        success: (response) => {
          commit('update', { key: 'tags', value: response });
        },
      });
    },
  },
};
