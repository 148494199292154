import Network from './network';

export default {
  updateOwnership(args, callbacks) {
    Network.put(
      `/celebrations/${args.record_id}/update_ownership_as_json`,
      args,
      callbacks,
    );
  },
};
