import Network from './network';

export default {
  getPaidCoffeeCostAndQuantity(args) {
    this.getCostAndQuantity('/payment/paid_coffee_cost_and_quantity', args);
  },

  getPremiumCostAndQuantity(args) {
    this.getCostAndQuantity('/payment/premium_cost_and_quantity', args);
  },

  getCostAndQuantity(basePath, args) {
    const urlParams = [];
    // TODO: Celebrations, even though this is mostly unused.
    if (args.coffeeConfigurationId) urlParams.push(`extra_config_ids=${args.coffeeConfigurationId}`);
    if (args.watercoolerId) urlParams.push(`extra_watercooler_ids=${args.watercoolerId}`);
    if (args.useCache) urlParams.push(`use_cache=${args.useCache}`);
    const url = `${basePath}?${urlParams.join('&')}`;
    Network.get(url, args);
  },
};
