export default {
  cancelModal({ commit, dispatch, state }) {
    if (
      state.modal.confirmOnCancel
      // eslint-disable-next-line no-alert
      && !window.confirm("Are you sure you want to cancel? You will lose any changes you've made.")
    ) {
      return;
    }
    if (state.modal.cancelMutation) {
      commit(state.modal.cancelMutation, state.modal.cancelArgs);
    } else {
      dispatch(state.modal.cancelAction || 'closeModal', state.modal.cancelArgs);
    }
  },

  closeModal({ commit, dispatch, state }) {
    if (state.modal.onClose) {
      dispatch(state.modal.onClose, state.modal.onCloseArgs);
    } else {
      commit('closeModal');
    }
  },
};
