export default {
  namespaced: true,

  state() {
    return {
      userList: [],
    };
  },

  mutations: {
    updateUserList(state, userList) {
      state.userList = userList;
    },
  },

  actions: {
    addUser({ state, rootState, commit }, user) {
      const alreadyOnList = state.userList.some(u => u.id === user.id);
      if (!alreadyOnList) {
        const list = state.userList;
        list.push(user);
        commit('updateUserList', list);
      }
    },

    removeUser({ state, commit }, user) {
      const list = state.userList;
      const index = list.indexOf(user);
      if (index >= 0) list.splice(index, 1);
      commit('updateUserList', list);
    },
  },
};
