import API from 'lib/api/api';

export default {
  updateServiceAccountAdmins({ commit, dispatch }, args) {
    const { recordId, user, actionType } = args;
    return new Promise((resolve, reject) => {
      API.ServiceAccounts.updateAdmins(recordId, user.id, actionType, {
        success: (data) => {
          commit('updateServiceAccountAdmins', data);
          const toastText = data.actionType === 'add' ? `Congrats! ${data.user} is now an admin of your account.` : `Ok, ${data.user} is no longer an admin.`;
          dispatch('setSuccessToast', toastText);
          resolve(true);
        },
        error: (error) => {
          dispatch('setErrorToast');
          resolve(false);
        },
      });
    });
  },

  updateServiceAccountOwnNewConfigs({ commit, dispatch }, { serviceAccountId, ownNewConfigs }) {
    return new Promise((resolve, reject) => {
      API.ServiceAccounts.updateOwnNewConfigs(serviceAccountId, ownNewConfigs, {
        success: (data) => {
          let isDefault = null;
          if (data.added_flag) {
            isDefault = true;
          } else if (data.removed_flag) {
            isDefault = false;
          }
          if (isDefault !== null) {
            dispatch('setSuccessToast', `Success! Your account is${isDefault ? '' : ' not'} covering any new Donut channels created on your team.`);
            commit('updateOwnNewConfigs', isDefault);
          }
          resolve(true);
        },
        error: (error) => {
          dispatch('setErrorToast', 'Uh oh! Something went wrong...');
          resolve(false);
        },
      });
    });
  },

  updateServiceAccountOwner({ commit, dispatch }, newOwnerId) {
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      API.ServiceAccounts.updateAccountOwner(newOwnerId, {
        success: (data) => {
          commit('updateAccountOwner', data);
          resolve(true);
        },
        error: (error) => {
          dispatch('setErrorToast');
          resolve(false);
        },
        complete: () => {
          commit('update', { key: 'formSubmitted', value: false });
          commit('closeModal');
        },
      });
    });
  },
};
