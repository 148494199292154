import API from 'lib/api/api';

export default {
  getCelebrationMessages({ commit, state }) {
    commit('startLoading', 'getCelebrationMessages');
    return new Promise((resolve, reject) => {
      API.CelebrationEvents.index(
        state.celebrationProgram.id,
        {
          success: (data) => {
            commit('update', { key: 'celebrationMessages', value: data });
            resolve(true);
          },
          error: (error) => {
            resolve(false);
          },
          complete: () => { commit('endLoading', 'getCelebrationMessages'); },
        },
      );
    });
  },
};
