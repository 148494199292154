import Network from './network';

export default {
  createAssets(data, args) {
    args.contentType = 'multipart/form-data';
    args.resourceName = 'asset';

    Network.post(
      '/onboarding/assets',
      data,
      args,
    );
  },

  getAssets(assetId, args) {
    Network.get(`/onboarding/assets/${assetId}`, args);
  },
};
