import Network from './network';

export default {
  getPresets(tag, args) {
    Network.get(
      `/onboarding/tags/${tag.id}/presets`,
      args,
    );
  },

  togglePreset(tag, preset, removing, channelID, args) {
    Network.put(
      `/onboarding/tags/${tag.id}/presets/${preset.id}`,
      { removing, channel_id: channelID },
      args,
    );
  },

  addPresetEvent(tag, presetEvent, day, channelID, args) {
    Network.post(
      `/onboarding/tags/${tag.id}/presets/${presetEvent.id}/add_event`,
      { days_later: day, channel_id: channelID },
      args,
    );
  },
};
