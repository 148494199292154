import Network from 'lib/api/network';
import _ from 'lodash';

export default {
  namespaced: true,

  state() {
    return {
      questionSets: [],
      categoryDescriptor: '',
      ordinalDescriptor: '',
    };
  },

  getters: {
    questionSetsByCategory(state) {
      const { questionSets, categoryDescriptor, ordinalDescriptor } = state;
      const categories = _.uniqBy(questionSets.map((questionSet) => ({ name: questionSet[categoryDescriptor], ordinal: questionSet[ordinalDescriptor] })), 'name');
      const sortedCategories = _.sortBy(categories, 'ordinal');

      return sortedCategories.map((category) => {
        const items = questionSets.filter((questionSet) => questionSet[categoryDescriptor] === category.name);
        return { ...category, items };
      });
    },

    questions: (state) => (questionSetId) => state.questionSets.find(questionSet => questionSet.id === questionSetId).questions,
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },

    updateQuestionSets(state, questionSets) {
      if (questionSets) {
        questionSets.forEach(questionSet => { questionSet.questions.forEach(question => { question.selected = true; }); });
        state.questionSets = questionSets;
      } else {
        state.questionSets = [];
      }
    },

    resetSelectedDrawerQuestions(state) {
      state.questionSets.forEach((questionSet) => {
        questionSet.questions.forEach((question) => {
          question.selected = true;
        });
      });
    },
  },

  actions: {
    fetchPresetEnrollmentSurveys({ commit, state, dispatch, rootState }) {
      return new Promise((resolve) => {
        Network.get('/onboarding/preset_enrollment_surveys/index_as_json', {
          success: (data) => {
            commit('updateQuestionSets', data.preset_enrollment_surveys);
            commit('update', { key: 'categoryDescriptor', value: 'flow_type_label' });
            commit('update', { key: 'ordinalDescriptor', value: 'flow_type_ordinal' });
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast', null, { root: true });
            resolve(false);
          },
        });
      });
    },
  },
};
