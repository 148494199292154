import API from 'lib/api/api';

export default {
  createWatercoolerPacksPrompt({ dispatch, commit, state }, { packId, promptId }) {
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      API.WatercoolerPacksPrompts.create(
        state.watercooler.id,
        packId,
        promptId,
        {
          success: (data) => {
            dispatch('updateTopicsQueue', data);
            dispatch('updatePacksLibrary', data);
            dispatch('setSuccessToast', 'Successfully added topic to pack.');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
          complete: () => {
            commit('closeModal');
            commit('update', { key: 'formSubmitted', value: false });
          },
        },
      );
    });
  },

  destroyWatercoolerPacksPrompt({ dispatch, state }, { packId, promptId }) {
    return new Promise((resolve, reject) => {
      API.WatercoolerPacksPrompts.destroy(
        state.watercooler.id,
        packId,
        promptId,
        {
          success: (data) => {
            dispatch('updateTopicsQueue', data);
            dispatch('updatePacksLibrary', data);
            dispatch('setSuccessToast', 'Successfully removed topic from pack.');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },

  reorderWatercoolerPacksPrompts({ state, dispatch }, { packId, promptId, direction }) {
    return new Promise((resolve, reject) => {
      API.WatercoolerPacksPrompts.reorder(
        state.watercooler.id,
        packId,
        promptId,
        direction,
        {
          success: (data) => {
            dispatch('updatePacksLibrary', data);
            dispatch('setSuccessToast');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
        },
      );
    });
  },
};
