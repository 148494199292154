import Network from './network';

export default {
  showPacksLibrary(watercoolerId, callbacks) {
    Network.get(`/watercooler/${watercoolerId}/packs_library_as_json`, callbacks);
  },

  update(watercoolerId, params, callbacks) {
    Network.put(
      `/watercooler/${watercoolerId}/update_as_json`,
      { watercooler: params },
      callbacks,
    );
  },

  updateOwnership(args, callbacks) {
    Network.put(
      `/watercooler/${args.record_id}/update_ownership_as_json`,
      args,
      callbacks,
    );
  },
};
